<template>
  <!-- Header -->
  <div>
    <SimpleModal
      title="Delete dataset"
      :name="dataset.name"
      :ok="doDelete"
      :hidden="cancelDelete"
    ></SimpleModal>
    <Header
      :entity="{}"
      :searchLabel="searchLabel"
      :action="addDataset"
      :actionLabel="actionLabel"
      :searchVisible="true"
    />
    <section class="main">
      <div>
        <!-- Add Dataset modal -->
        <b-modal
          id="add-dataset"
          ref="modal"
          title="Add Dataset"
          @ok="handleOk"
          :ok-disabled="addDatasetOkDisabled()"
          size="lg"
          @shown="initModal"
        >
          <form ref="form" @submit.prevent="handleSubmit" novalidate>
            <div class="form-group has-feedback">
              <input
                id="dataset-name-input"
                class="form-control"
                type="text"
                v-model="dataset.name"
                required
                autocomplete="off"
              />
              <label
                @click="utils.focus('dataset-name-input')"
                class="form-label"
                >Name</label
              >
            </div>

            <div class="form-group has-feedback mt-5">
              <multiselect
                id="content-type-input"
                v-model="dataset.content_type"
                :options="dataset_content_type_options"
                :show-labels="false"
                :allow-empty="false"
                placeholder="Select dataset content type"
                autocomplete="off"
              >
              </multiselect>
              <label class="form-label auto-adjust-form-label"
                >Content type</label
              >
            </div>
            <custom-checkbox
              title="Multi-label"
              :entity="dataset"
              property="multi_label"
              text="Allow multiple labels for every dataset item."
              input_id="multi-label-input"
              top_class="px-3"
            />
            <custom-checkbox
              title="Default splits"
              :entity="dataset"
              property="default_splits"
              text="Create default dataset splits: train, valid, test."
              input_id="default-splits-input"
              top_class="px-3"
            />
            <div class="form-group has-feedback mt-5">
              <textarea
                id="dataset-description-input"
                class="form-control"
                v-model="dataset.description"
                required
                rows="3"
              ></textarea>
              <label
                @click="utils.focus('dataset-description-input')"
                class="form-label"
                >Description</label
              >
            </div>
          </form>
        </b-modal>
      </div>
      <!-- Overview Datasets -->
      <div
        class="container-fluid"
        v-show="filteredDatasets.length == 0"
        v-cloak
      >
        <!-- TODO add empty state here -->
        <div class="row p-3 overview justify-content-center">
          {{ emptyState() }}
        </div>
      </div>
      <div class="container-fluid" v-show="datasets.length" v-cloak>
        <div class="row p-3 overview">
          <div
            v-for="dataset in filteredDatasets"
            class="col-lg-3 mb-5 d-flex align-items-stretch"
            :key="dataset.id"
          >
            <!-- Dataset card -->
            <div class="card dataset">
              <img
                :src="getImgUrl(dataset)"
                img-alt="Dataset logo"
                @click="showDataset(dataset)"
                :class="getImgClass(dataset)"
                class="pointer img-fluid mx-auto d-block img-card-top"
              />
              <div class="card-body d-flex flex-column">
                <h5 class="card-title h2 pointer" @click="showDataset(dataset)">
                  {{ dataset.name }}
                </h5>
                <p class="card-text mb-4 text-left truncate-3 min-height-3">
                  {{ dataset.description }}
                </p>
                <div>
                  <a
                    v-if="utils.canEdit(dataset)"
                    v-on:click.stop.prevent
                    @click="editDataset(dataset)"
                    class="btn"
                  >
                    <i class="far fa-edit" />
                  </a>
                  <a
                    v-if="utils.canDelete(dataset)"
                    v-on:click.stop.prevent
                    @click="startDelete(dataset)"
                    class="btn"
                    href="#"
                  >
                    <i class="far fa-trash-alt" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

export default {
  name: "Datasets",
  data: function() {
    return {
      defaultDataset: {
        name: "",
        description: "",
        content_type: utils.dataset_content_type_images,
        default_splits: true,
        multi_label: false
      },
      search: "",
      datasets: [],
      filtered_datasets: [],
      share_type: "all",
      actionLabel: "Add dataset",
      searchLabel: "Search datasets...",
      dataset: {},
      nameState: null,
      dataset_content_type_options: utils.dataset_content_types,
      utils: utils
    };
  },
  computed: {
    filteredDatasets: function() {
      var search = this.search.toLowerCase();

      return this.filtered_datasets.filter(dataset => {
        if (search == "" || search.length < 2) return true;

        return (
          dataset.name.toLowerCase().includes(search) ||
          dataset.description.toLowerCase().includes(search)
        );
      });
    }
  },
  methods: {
    filter_datasets() {
      return this.datasets.filter(dataset => {
        // Todo: Add sharing of datasets
        if (this.share_type == "all") {
          return true;
        } else if (this.share_type == "public") {
          return dataset.public;
        }

        if (this.share_type == "owned") {
          return dataset.user_id == utils.user_id();
        } else if (this.share_type == "shared") {
          return dataset.shared_with_me;
        }

        return true;
      });
    },
    emptyState: function() {
      return "There are no datasets available for you at the moment.";
    },
    refreshDatasets() {
      this.$http
        .get(utils.datasetsApi)
        .then(response => {
          var sorted = this.utils.sort_dates(response.data, "updated_at", 1);
          this.datasets = sorted;
          this.filtered_datasets = sorted;
          this.dataset = this.defaultDataset;

          if (this.$route.query.filter) {
            this.share_type = this.$route.query.filter;
            this.filtered_datasets = this.filter_datasets();
          }
        })
        .catch(() => {
          utils.redictLogin(this, this.$route.fullPath);
        });
    },
    addDatasetOkDisabled: function() {
      if (this.dataset && this.dataset.name && this.dataset.name.length < 1) {
        return true;
      }

      return false;
    },
    addDataset: function() {
      this.$bvModal.show("add-dataset");
    },
    initModal: function() {
      var datasetNameInput = this.utils.el("dataset-name-input");
      datasetNameInput.focus();
    },
    handleOk() {
      this.$http.post(utils.datasetsApi, this.dataset).then(res => {
        this.dataset = this.defaultDataset;
        this.$bvModal.hide("add-dataset");

        //this.refreshDatasets();
        this.showDataset(res.body);
      });
    },
    startDelete: function(dataset) {
      this.dataset = dataset;
      this.$bvModal.show("simple-modal");
    },
    doDelete: function() {
      this.$ga.event("delete-dataset");

      this.datasets = this.datasets.filter(ds => {
        return ds["id"] != this.dataset["id"];
      });

      this.filtered_datasets = this.datasets;

      if (this.$route.query.filter) {
        this.share_type = this.$route.query.filter;
        this.filtered_datasets = this.filter_datasets();
      }

      this.$http.delete(utils.datasetsApi + "/" + this.dataset.id).then(() => {
        this.refreshDatasets();
      });
    },
    cancelDelete: function() {
      this.dataset = this.defaultDataset;
    },
    showDataset(dataset) {
      this.$router.push(`/datasets/${dataset.id}`);
    },
    editDataset: function(dataset) {
      this.$router.push(`/datasets/${dataset.id}/edit`);
    },
    getImgUrl: function(dataset) {
      if (dataset.has_logo) {
        return `${utils.host}/datasets/${dataset.id}/logo`;
      }

      return require("../img/seeme-ai.svg");
    },
    getImgClass: function(dataset) {
      if (dataset.has_logo) {
        return "";
      }

      return "dataset-logo";
    },
    updateSearch: function(val) {
      this.search = val;
      this.filtered_datasets = this.filter_datasets();
    },
    updateShareType: function(item) {
      this.share_type = item.id;

      this.filtered_datasets = this.filter_datasets();
    },
    toggleMultiLabel: function() {
      this.dataset.multi_label = !this.dataset.multi_label;
    },
    toggleCreateDefaults: function() {
      this.dataset.default_splits = !this.dataset.default_splits;
    }
  },
  mounted() {
    this.dataset = this.defaultDataset;
    this.refreshDatasets();
    this.$ga.page("/datasets");

    this.$root.$on("updated_search", val => {
      this.updateSearch(val);
    });

    this.$root.$on("updated_share_type", item => {
      this.updateShareType(item);
    });

    utils.showMenu(true, true, this);
  }
};
</script>
