<template>
  <div>
    <Header
      :entity="model"
      :showLogo="true"
      :actionLabel="actionLabel"
      :action="editModel"
      :hidden="!utils.canEdit(model)"
    />
    <section class="main pt-5">
      <div class="center pt-5 mt-5">
        <div class="container-fluid" v-if="canInference()">
          <ImageClassification
            v-if="isApplication('image_classification')"
            :endpoint="getInferenceEndpoint()"
          />

          <TextClassification
            v-if="isApplication('text_classification')"
            :endpoint="getInferenceEndpoint()"
          />

          <ObjectDetection
            v-if="isApplication('object_detection')"
            :endpoint="getInferenceEndpoint()"
            :model_config="getConfig()"
          />

          <NamedEntityRecognition
            v-if="isApplication('ner')"
            :endpoint="getInferenceEndpoint()"
            :model_config="getConfig()"
          />

          <Tabular
            v-if="isApplication('structured')"
            :endpoint="getInferenceEndpoint()"
            :model_config="getConfig()"
          />

          <OpticalCharacterRecognition
            v-if="isApplication('ocr')"
            :endpoint="getInferenceEndpoint()"
          />

          <LanguageModel
            v-if="isApplication('language_model')"
            :endpoint="getInferenceEndpoint()"
          />

          <LLM v-if="isApplication('llm')" :endpoint="getInferenceEndpoint()" />

          <SpeechToText
            v-if="isApplication('speech_to_text')"
            :endpoint="getInferenceEndpoint()"
          />

          <Search
            v-if="isApplication('search')"
            :endpoint="getInferenceEndpoint()"
          />
        </div>
        <div v-else>TODO: upload your model here</div>
      </div>
    </section>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";

const analyzeLbl = "Analyze";

export default {
  name: "Model",
  data: function() {
    return {
      model: {},
      version: {},
      config: {},
      actionLabel: "Edit",
      action: function() {},

      inferenceOptionSelection: [],
      inferenceOptions: [],
      utils: utils
    };
  },
  methods: {
    getModel: function() {
      this.$http
        .get(utils.modelApi + "/" + this.$route.params.id)
        .then(response => {
          this.model = response.data;

          if (this.$route.params.version_id) {
            this.getVersion();
          }
        })
        .catch(err => {
          if (err.status == this.utils.http_status_not_found) {
            this.$router.push({
              name: "NotFound"
            });
          } else {
            this.$router.push({
              name: "Login",
              query: { redirect: this.$route.fullPath }
            });
          }
        });
    },
    getVersion: function() {
      this.$http
        .get(
          `${utils.modelApi}/${this.$route.params.id}/versions/${this.$route.params.version_id}`
        )
        .then(res => {
          this.version = res.data;
        });
    },
    editModel: function() {
      this.$router.push(`/models/${this.model.id}/edit`);
    },
    getConfig: function() {
      if (this.$route.params.version_id) {
        return this.version.config;
      }

      return this.model.config;
    },
    canInference: function() {
      if (this.version.id) {
        return this.version.can_inference;
      }

      return this.model.can_inference;
    },

    myEventHandler() {
      this.visualHeight = window.innerHeight * 0.5;
      this.visualWidth = window.innerWidth * 0.5;
    },
    textInputFocus: function() {
      var hl = document.getElementById("highlights");
      hl.innerHTML = "";
    },
    handleScroll: function() {
      var textarea = document.getElementById("textarea");
      var backdrop = document.getElementById("backdrop");
      var scrollTop = textarea.scrollTop;
      backdrop.scrollTop = scrollTop;

      var scrollLeft = textarea.scrollLeft;
      backdrop.scrollLeft = scrollLeft;
    },
    isApplication: function(application) {
      return this.model.kind == application;
    },
    getInferenceEndpoint: function() {
      var url = `${utils.inferencesApi}/${this.model.id}`;

      if (this.version.id) {
        url = `${url}/versions/${this.version.id}`;
      }

      return url;
    },
    analyze: function(model) {
      this.$ga.event("analyze");
      var self = this;
      this.btnLbl = "Analyzing...";
      this.result = "";
      this.detectionResult = {};
      this.confidence = "";

      var xhr = new XMLHttpRequest();

      var url = this.getInferenceEndpoint();

      if (
        this.inferenceOptionSelection &&
        Object.keys(this.inferenceOptionSelection).length != 0
      ) {
        for (const property in this.inferenceOptionSelection) {
          url = `${url}?${property}=${this.inferenceOptionSelection[property].value}`;
        }
      }

      xhr.open("POST", url, true);
      xhr.setRequestHeader(
        "Authorization",
        `${localStorage.getItem("username")}:${localStorage.getItem("apikey")}`
      );
      xhr.onerror = function() {
        alert(xhr.responseText);
      };
      xhr.onload = function(e) {
        if (this.readyState === 4) {
          var response = JSON.parse(e.target.responseText);

          if (self.model.kind == "ner") {
            var annotations = response.inference_items.map(i => {
              if (!self.config["colors"][i.prediction]) {
                self.config["colors"][i.prediction] = utils.random_color();
              }
              return {
                coordinates: i.coordinates,
                label_id: i.prediction
              };
            });

            utils.highlight(
              "highlights",
              self.nlpInput,
              {},
              annotations,
              self.config.colors,
              utils.default_selection_color
            );

            self.handleScroll();
          } else if (self.model.kind == "ocr") {
            self.ocrOutput = response.inference_items[0].prediction;
          } else if (self.isClassification) {
            self.result = response.prediction;
            self.confidence = response.confidence;
          } else if (self.isLanguageModel) {
            self.nlpInput = response.prediction;
          } else {
            self.detectionResult = JSON.parse(response.result);

            var canvasHeight = self.visualHeight;
            var canvasWidth = self.visualWidth;

            if (self.detectionResult.boxes != undefined) {
              self.detectionResult.boxes.forEach(e => {
                var coords = e.coordinates;

                if (!self.config["colors"][e.label]) {
                  self.config["colors"][e.label] = utils.random_color();
                }
                ctx.strokeStyle = self.config["colors"][e.label];

                let x1 = Math.round(coords.x_min * canvasWidth);
                let y1 = Math.round(coords.y_min * canvasHeight);
                let x2 = Math.round(
                  (coords.x_max - coords.x_min) * canvasWidth
                );
                let y2 = Math.round(
                  (coords.y_max - coords.y_min) * canvasHeight
                );

                ctx.strokeRect(x1 + 0.5, y1 + 0.5, x2, y2);
              });
            }
          }
          self.btnLbl = analyzeLbl;
        }
      };

      if (
        model["kind"] == "text_classification" ||
        model["kind"] == "language_model" ||
        model["kind"] == "ner"
      ) {
        xhr.setRequestHeader("Content-Type", "application/json");

        this.nlpInput = this.nlpInput.trim();

        var data = {
          input_text: this.nlpInput
        };

        var textarea = document.getElementById("textarea");

        if (textarea) {
          textarea.scrollTop = 0;
        }

        xhr.send(JSON.stringify(data));
      } else if (model["kind"] == "structured") {
        xhr.setRequestHeader("Content-Type", "application/json");

        var structured_data = {
          input_text: JSON.stringify(this.structuredRequest)
        };

        xhr.send(JSON.stringify(structured_data));
      } else {
        if (model["kind"] == "object_detection") {
          var canvas = document.getElementById("canvas");
          var ctx = canvas.getContext("2d");
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          this.detectionResult = {};
        }

        this.result = "";

        var uploadFiles = utils.el("file-input").files;

        if (uploadFiles.length != 1) alert("Please select 1 file to analyze!");

        var fileData = new FormData();
        fileData.append("file", uploadFiles[0]);
        xhr.send(fileData);
      }
    }
  },
  computed: {
    detectionCounts: function() {
      if (!this.detectionResult["count"]) {
        return {};
      }
      var count = this.detectionResult["count"].filter(c => {
        return c["total"] > 0;
      });

      return count;
    }
  },
  mounted() {
    utils.showMenu(false, false, this);

    this.getModel();
    this.$ga.page("model");
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  }
};
</script>

<style scoped></style>
