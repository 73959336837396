<template>
  <div>
    <!-- Press right -->
    <Keypress key-event="keyup" :key-code="39" @success="keyNavigate(true)" />
    <!-- Press left -->
    <Keypress key-event="keyup" :key-code="37" @success="keyNavigate(false)" />
    <!-- Press Esc -->
    <Keypress key-event="keyup" :key-code="27" @success="handleEsc()" />
    <Keypress
      key-event="keyup"
      :multiple-keys="letterKeys"
      @success="handleKeyAnnotation"
    />
    <SimpleModal
      :title="getModalTitle()"
      :name="getModalName()"
      :ok="doDelete"
      :hidden="cancelDelete"
    ></SimpleModal>
    <!-- Side menu -->
    <div
      id="sidemenu"
      class="v-sidebar-menu vsm_expanded vsm_white-theme"
      style="max-width: 350px; display: block"
    >
      <div>
        <div class="dropdown-and-add">
          <div class="select" style="width: 100%">
            <multiselect
              v-model="selectedVersion"
              :options="versions"
              :multiple="false"
              :close-on-select="true"
              :preseve-search="true"
              label="name"
              placeholder="Select a version"
              @select="selectVersion"
              :allowEmpty="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="props"
                ><span class="option__title"
                  >Version:
                  <span class="font-weight-bold">{{ props.option.name }}</span>
                </span></template
              >
              <template slot="option" slot-scope="props">
                <div class="option__desc option-edit">
                  <span class="option__title dropdown-line-item">{{
                    props.option.name
                  }}</span>
                  <span class="">
                    <a
                      @click.stop.prevent="manageVersion(props.option, false)"
                      class="dropdown-line-item"
                      v-if="utils.canEdit(dataset)"
                    >
                      <i class="fas fa-edit" />
                    </a>
                    <a
                      @click.prevent.stop="downloadVersion(props.option)"
                      class="dropdown-line-item"
                    >
                      <i
                        v-if="isDownloadVersion(props.option)"
                        class="fas fa-spinner fa-pulse"
                      />
                      <i v-else class="fas fa-download" />
                    </a>
                    <a
                      @click.prevent.stop="
                        startDelete(props.option, modal_state_version)
                      "
                      @click.prevent
                      class="dropdown-line-item"
                      v-if="utils.canDelete(dataset)"
                    >
                      <i class="far fa-trash-alt" />
                    </a>
                    <a class="dropdown-line-item disabled" v-else>
                      <i class="far fa-trash-alt grey" />
                    </a>
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
          <a
            @click.stop.prevent="manageVersion({}, true)"
            class="float-right add"
            v-if="utils.canEdit(dataset)"
          >
            <i class="fas fa-plus" />
          </a>
        </div>
      </div>
      <hr />
      <!-- Split dropdown -->
      <div id="splits-dropdown" class="">
        <div class="dropdown-and-add">
          <div class="select" style="width: 100%">
            <multiselect
              v-model="selectedSplit"
              :options="splits"
              :multiple="false"
              :close-on-select="true"
              :preseve-search="true"
              label="name"
              placeholder="Select a split"
              @select="selectSplit"
              :allowEmpty="false"
              :show-labels="false"
            >
              <template slot="singleLabel" slot-scope="props"
                ><span class="option__title"
                  >Split:
                  <span class="font-weight-bold">{{ props.option.name }}</span>
                </span></template
              >
              <template slot="option" slot-scope="props">
                <div class="option__desc option-edit">
                  <span class="option__title dropdown-line-item">{{
                    props.option.name
                  }}</span>
                  <span class="">
                    <a
                      @click.stop.prevent="manageSplit(props.option, false)"
                      class="dropdown-line-item"
                      v-if="utils.canEdit(dataset)"
                    >
                      <i class="fas fa-edit" />
                    </a>
                    <a
                      @click.prevent.stop="
                        startDelete(props.option, modal_state_split)
                      "
                      class="dropdown-line-item"
                      v-if="utils.canDelete(dataset)"
                    >
                      <i class="far fa-trash-alt" />
                    </a>
                    <a class="dropdown-line-item disabled" v-else>
                      <i class="far fa-trash-alt grey" />
                    </a>
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
          <a
            @click.stop.prevent="manageSplit({}, true)"
            class="float-right add"
            v-if="utils.canEdit(dataset)"
          >
            <i class="fas fa-plus" />
          </a>
        </div>
      </div>

      <!-- Label filter -->
      <div class="label-area">
        <div id="filter-area" class="add-label-section">
          <input
            v-model="labelSearch"
            :placeholder="LabelFilterPlaceholder()"
            @focus="togglePreventAnnotation(true)"
            @blur="togglePreventAnnotation(false)"
            class="form-control"
          />
          <a
            @click.stop.prevent="clickLabelSort()"
            class="add-split col-xs add"
            v-b-popover.hover.top="getSortLabel()"
          >
            <i class="fas fa-sort" />
          </a>
        </div>

        <!-- Label List -->
        <div class="label-list">
          <div>
            <input
              id="add-item"
              class="no-display"
              type="file"
              name="file"
              multiple
              :accept="getSupportedMIMEtypes()"
              @change="startUpload"
            />
          </div>
          <div
            v-show="labels.length"
            v-cloak
            :style="`height: ${labelHeight}px`"
          >
            <div
              v-for="(label, index) in filteredLabels"
              v-bind:key="label.id"
              class="label"
            >
              <!-- Label Template -->
              <div
                class="row view"
                :class="{ selectedLabel: selectedLabel === label }"
              >
                <a @click="selectLabel(label)" class="col-sm label-url">
                  <i
                    :style="'color:' + label.color + ';'"
                    class="fa fa-square-full"
                  />
                  <span class="label-name">
                    <div class="label-title" :title="labelname(label)">
                      {{ labelname(label) }}
                    </div>
                  </span>
                  <!-- <span class="label-name" :title="labelname(label)">{{ labelname(label) }}</span> -->
                  <span
                    v-if="dataset.multi_label"
                    class="label-count float-right"
                    v-b-popover.hover.top="getLabelStatsTitle(label)"
                  >
                    ({{ getAnnotationCount(label) }} -
                    {{ getItemCount(label) }})
                  </span>
                  <span
                    v-else
                    class="label-count float-right"
                    v-b-popover.hover.top="getLabelStatsTitle(label)"
                  >
                    ({{ getItemCount(label) }})
                  </span>
                </a>
                <a
                  v-if="
                    (!dataset.multi_label && utils.canEdit(dataset)) ||
                      dataset.content_type ==
                        utils.dataset_content_type_documents
                  "
                  @click="selectImagesForLabel(label)"
                  class="lbl-btn col-xs"
                >
                  <i class="fas fa-upload" />
                </a>
                <a
                  @click="editLabel(label, index)"
                  class="lbl-btn col-xs"
                  v-if="utils.canEdit(dataset)"
                >
                  <i class="fas fa-edit" />
                </a>
                <a
                  @click.prevent.stop="startDelete(label, modal_state_label)"
                  class="lbl-btn col-xs"
                  v-if="canDelete(label)"
                >
                  <i class="far fa-trash-alt" />
                </a>
                <a
                  class="lbl-btn col-xs disabled"
                  v-else
                  title="No permission to these this label"
                >
                  <i class="far fa-trash-alt grey" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Menu footer -->
      <div id="dataset-footer">
        <div>
          <!-- Unlabelled data -->
          <div class="row view" :class="{ selectedLabel: onlyUnlabelled }">
            <a @click="showUnlabelled()" class="col-sm">
              Unlabelled ({{ getItemCount("unlabelled") }})
            </a>
            <a
              @click="selectUnlabelledImages()"
              class="btn col-xs"
              v-if="canAddItem"
            >
              <i class="fas fa-upload" />
            </a>
            <a v-else class="btn col-xs">
              <i class="fas fa-upload grey" />
            </a>
          </div>
          <!-- Add new Label -->
          <div class="add-label-section" v-if="utils.canEdit(dataset)">
            <b-form-input
              v-model="newLabel"
              placeholder="New label"
              v-on:keyup.enter="addLabel"
              @focus="togglePreventAnnotation(true)"
              @blur="togglePreventAnnotation(false)"
            />
            <a
              v-if="canAddLabel"
              @click="addLabel"
              class="add-split col-xs add"
            >
              <i class="fas fa-plus" />
            </a>
            <a v-else class="add-split col-xs add disabled">
              <i class="fas fa-plus grey" />
            </a>
          </div>
        </div>
        <div style="margin-right: 40px">
          <multiselect
            v-model="selectedSort"
            :options="sortOptions"
            :multiple="false"
            :close-on-select="true"
            :preseve-search="true"
            label="label"
            placeholder="Sort items by:"
            @select="selectSort"
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
          />
        </div>
      </div>
    </div>
    <div>
      <!-- Manage label Modal -->
      <b-modal
        id="edit-label"
        title="Edit Label"
        @show="resetModal"
        @hidden="resetModal"
        @ok="updateLabel"
        :ok-disabled="modalOkDisabled(selectedEditLabel)"
      >
        <form class="mt-3" novalidate>
          <div class="form-group has-feedback">
            <input
              id="label-name-input"
              class="form-control"
              type="text"
              v-model="selectedEditLabel.name"
              required
            />
            <label @click="utils.focus('label-name-input')" class="form-label"
              >Name</label
            >
          </div>
          <div class="form-group has-feedback mt-5">
            <textarea
              id="label-description-input"
              class="form-control"
              v-model="selectedEditLabel.description"
              required
              rows="2"
            ></textarea>
            <label
              @click="utils.focus('label-description-input')"
              class="form-label"
              >Description</label
            >
          </div>
          <div class="form-group has-feedback mt-5">
            <input
              class="form-control"
              type="color"
              v-model="selectedEditLabel.color"
              required
            />
            <label class="form-label">Color</label>
          </div>
          <div class="form-group has-feedback">
            <input
              class="form-control disabled-input"
              type="text"
              v-model="selectedEditLabel.index"
              required
              onkeypress="return false;"
            />
            <label class="form-label">Index</label>
          </div>
          <div class="form-group has-feedback">
            <input
              id="shortcut-input"
              class="form-control"
              type="text"
              v-model="selectedEditLabel.shortcut"
              required
              :maxlength="1"
              @input="checkLabelShortcut"
            />
            <label @click="utils.focus('shortcut-input')" class="form-label"
              >Shortcut</label
            >
          </div>
          <div class="form-group has-feedback">
            <input
              class="form-control disabled-input sided-with-copy"
              type="text"
              v-model="selectedEditLabel.id"
              required
              onkeypress="return false;"
            />
            <label class="form-label">Id</label>
            <a
              class="hover-larger pointer"
              @click="copyToClipboard(selectedEditLabel.id, 'label')"
              @mouseout="resetCopyLabel()"
              v-b-popover.hover.top="getCopyLabel('label')"
              ><i class="fas fa-copy"
            /></a>
          </div>
          <div class="error" v-if="labelError.length > 0">
            {{ labelError }}
          </div>
        </form>
      </b-modal>
      <!-- Train model -->
      <b-modal
        id="train-dataset"
        title="Job: Train Request"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleAddTrain"
        :ok-disabled="disabledTrainOk()"
      >
        <form novalidate>
          <div class="form-group has-feedback mt-3">
            <input
              id="job-name-input"
              class="form-control"
              type="text"
              v-model="jobRequest.name"
              required
            />
            <label @click="utils.focus('job-name-input')" class="form-label"
              >Model/Version name</label
            >
          </div>
          <div class="form-group has-feedback mt-5">
            <multiselect
              v-model="selectedModel"
              :options="models"
              :close-on-select="true"
              :preserve-search="true"
              placeholder="Or leave empty to add a new model"
              label="name"
              :show-labels="false"
              class="form-control"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ getSelectionLabel(values) }}</span
                ></template
              >
              <template slot="noResult">
                <span>No model name matches that search...</span>
              </template>
            </multiselect>
            <label class="form-label auto-adjust-form-label"
              >Add as a new version for model</label
            >
          </div>
          <div class="form-group has-feedback mt-5">
            <textarea
              id="job-description-input"
              class="form-control"
              v-model="jobRequest.description"
              required
              rows="1"
            ></textarea>
            <label
              @click="utils.focus('job-description-input')"
              class="form-label"
              >Description</label
            >
          </div>

          <b-form-group>
            <div>
              <b-button
                v-b-toggle.collapse-1
                variant="primary"
                style="width: 150px"
                >Advanced</b-button
              >
              <b-collapse id="collapse-1" class="mt-4">
                <div class="form-group has-feedback mt-5">
                  <multiselect
                    v-model="selected"
                    :options="options"
                    :close-on-select="true"
                    :preserve-search="true"
                    placeholder="Select an architecture"
                    label="name"
                    :show-labels="false"
                    :allowEmpty="false"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ getSelectionLabel(values) }}</span
                      ></template
                    >
                  </multiselect>
                  <label class="form-label auto-adjust-form-label"
                    >Select framework/architecture</label
                  >
                </div>
                <div v-for="item in selected.items" v-bind:key="item.name">
                  <div v-if="!item.hidden" class="form-group has-feedback mt-5">
                    <div v-if="item.value_type == 'multi'">
                      <multiselect
                        v-model="item.value"
                        :options="item.options"
                        :multiple="item.multiple"
                        :close-on-select="!item.multiple"
                        :preserve-search="true"
                        :placeholder="item.label"
                        :show-labels="false"
                        :allowEmpty="!item.required"
                      >
                      </multiselect>
                      <label class="form-label auto-adjust-form-label">{{
                        item.label
                      }}</label>
                    </div>

                    <custom-checkbox
                      v-else-if="
                        item.value_type == utils.job_value_type_boolean
                      "
                      :entity="item"
                      property="value"
                      :title="item.name"
                      :text="item.label"
                      input_id="add_info"
                    />
                    <div v-else>
                      <input
                        class="form-control"
                        :id="item.name"
                        :type="utils.js_type_from_value_type(item.value_type)"
                        v-model="item.value"
                        required
                        :step="item.step ? item.step : 1"
                      />
                      <label
                        @click="utils.focus(item.name)"
                        class="form-label"
                        >{{ item.label }}</label
                      >
                    </div>
                  </div>
                </div>
                <div
                  v-if="selectedApplication.continual_training"
                  class="form-group has-feedback mt-5"
                >
                  <multiselect
                    v-model="selectedStartModel"
                    :options="models"
                    :close-on-select="true"
                    :preserve-search="true"
                    placeholder="Select a model to start from"
                    label="name"
                    :show-labels="false"
                    :allowEmpty="true"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ getSelectionLabel(values) }}</span
                      ></template
                    >
                  </multiselect>
                  <label class="form-label auto-adjust-form-label"
                    >Continue from model</label
                  >
                </div>

                <div
                  v-if="selectedApplication.continual_training"
                  class="form-group has-feedback mt-5"
                >
                  <multiselect
                    v-model="selectedStartModelVersion"
                    :options="startModelVersions"
                    :close-on-select="true"
                    :preserve-search="true"
                    placeholder="Select a model version to start from"
                    label="name"
                    :show-labels="false"
                    :allowEmpty="true"
                    :disabled="
                      !selectedApplication.continual_training &&
                        selectedStartModel &&
                        selectedStartModel.id
                    "
                    :custom-label="customModelVersionLabel"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                      ><span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                        >{{ getSelectionLabel(values) }}</span
                      ></template
                    >
                  </multiselect>
                  <label class="form-label auto-adjust-form-label"
                    >Continue from model version</label
                  >
                </div>
              </b-collapse>
            </div>
          </b-form-group>
        </form>
      </b-modal>
      <!-- Modal: Manage version -->
      <b-modal
        id="manage-version"
        :title="manageVersionLabel"
        @show="resetModal"
        @hidden="resetModal"
        @ok="addVersion"
        :ok-disabled="modalOkDisabled(versionToManage)"
      >
        <form novalidate>
          <div class="form-group has-feedback">
            <input
              id="version-name-input"
              class="form-control"
              type="text"
              v-model="versionToManage.name"
              required
            />
            <label @click="utils.focus('version-name-input')" class="form-label"
              >Name</label
            >
          </div>
          <div class="form-group has-feedback mt-5">
            <textarea
              id="version-description-input"
              class="form-control"
              v-model="versionToManage.description"
              required
              rows="10"
            ></textarea>
            <label
              @click="utils.focus('version-description-input')"
              class="form-label"
              >Description</label
            >
          </div>
          <custom-checkbox
            v-if="!versionToManage.dataset_id"
            title="Empty version"
            :entity="this"
            property="newCleanVersion"
            text="Create a new empty dataset version"
            input_id="emtpy-version-input"
            top_class="px-3"
          />

          <div
            v-if="versionToManage.splits"
            class="form-group has-feedback mt-5"
          >
            <multiselect
              v-model="selectedDefaultSplit"
              :options="versionToManage.splits || []"
              :multiple="false"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              placeholder="Select a default split"
              label="name"
              :show-labels="false"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }"
                ><span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >fout</span
                ></template
              >
            </multiselect>
            <label class="form-label auto-adjust-form-label"
              >Default split</label
            >
          </div>
          <div class="form-group has-feedback mt-5">
            <textarea
              id="version-config-input"
              class="form-control"
              v-model="versionToManage.config"
              required
              rows="3"
            ></textarea>
            <label
              @click="utils.focus('version-config-input')"
              class="form-label"
              >Config</label
            >
          </div>

          <div class="form-group has-feedback" v-if="versionToManage.id">
            <input
              class="form-control disabled-input sided-with-copy"
              type="text"
              v-model="versionToManage.id"
              required
              onkeypress="return false;"
            />
            <label class="form-label">Id</label>
            <a
              class="hover-larger pointer"
              @click="copyToClipboard(versionToManage.id, 'version')"
              @mouseout="resetCopyLabel()"
              v-b-popover.hover.top="getCopyLabel('version')"
              ><i class="fas fa-copy"
            /></a>
          </div>
          <hr />
          <div
            class="form-group has-feedback pt-3"
            v-if="versionToManage.dataset_id"
          >
            <input
              class="form-control disabled-input sided-with-copy"
              type="text"
              v-model="versionToManage.dataset_id"
              required
              onkeypress="return false;"
            />
            <label class="form-label pt-3">Dataset id</label>
            <a
              class="hover-larger pointer"
              @click="copyToClipboard(versionToManage.dataset_id, 'dataset')"
              @mouseout="resetCopyLabel()"
              v-b-popover.hover.top="getCopyLabel('dataset')"
              ><i class="fas fa-copy"
            /></a>
          </div>
        </form>
      </b-modal>
      <!-- Modal: Manage Split -->
      <b-modal
        id="manage-split"
        :title="manageSplitLabel"
        @show="resetModal"
        @hidden="resetModal"
        @ok="addOrUpdateSplit"
        :ok-disabled="modalOkDisabled(splitToManage)"
      >
        <form novalidate>
          <div class="form-group has-feedback">
            <input
              id="split-name-input"
              class="form-control"
              type="text"
              v-model="splitToManage.name"
              required
            />
            <label @click="utils.focus('split-name-input')" class="form-label"
              >Name</label
            >
          </div>
          <div class="form-group has-feedback mt-5">
            <textarea
              id="split-description-input"
              class="form-control"
              v-model="splitToManage.description"
              required
              rows="10"
            ></textarea>
            <label
              @click="utils.focus('split-description-input')"
              class="form-label"
              >Description</label
            >
          </div>
          <div class="form-group has-feedback" v-if="splitToManage.id">
            <input
              class="form-control disabled-input sided-with-copy"
              type="text"
              v-model="splitToManage.id"
              required
              onkeypress="return false;"
            />
            <label class="form-label">Id</label>
            <a
              class="hover-larger pointer"
              @click="copyToClipboard(splitToManage.id, 'split')"
              @mouseout="resetCopyLabel()"
              v-b-popover.hover.top="getCopyLabel('split')"
              ><i class="fas fa-copy"
            /></a>
          </div>
        </form>
      </b-modal>
    </div>
    <!-- Header component config -->
    <Header
      :entity="dataset"
      :action="trainVersion"
      :actionLabel="trainLabel"
      :hidden="hideTraining()"
      :showLogo="true"
      url="/#/datasets"
      title="Back to datasets overview"
    />
    <!-- Body of the page -->
    <!-- Iterate through pages -->
    <div
      class="container-fluid upload overview mt-5"
      v-show="filteredItems.length == 0"
      v-cloak
    >
      <div class="py-5">
        <div class="my-4">
          <div v-if="!showSpinner()" class="row justify-content-center">
            Start creating your dataset
          </div>
          <div v-if="!showSpinner()" class="row">
            <div
              v-for="tip in uploadTips"
              class="col-lg-4 mb-5 d-flex align-items-stretch"
              :key="tip.name"
            >
              <!-- Dataset card -->
              <div class="card" :class="getTipOpacity(tip.name)">
                <div class="card-body d-flex flex-column">
                  <h5 class="card-title h3 help-title">
                    {{ tip.name }}
                  </h5>
                  <img
                    :src="require(`../img/${tip.img}`)"
                    :img-alt="tip.imgAlt"
                    class="help-image"
                  />
                  <p class="card-text p-3 text-left truncate-3">
                    {{ tip.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div v-if="showUploadButton()">-- or --</div>
            <div v-if="showUploadButton()" class="row justify-content-center">
              Upload a dataset ".zip file" below:
            </div>
            <form id="dataset-input-form">
              <multiselect
                v-model="datasetFormat"
                :options="datasetFormatOptions"
                :multiple="false"
                :close-on-select="true"
                :preseve-search="true"
                :show-labels="false"
                :allow-empty="false"
                v-if="showUploadButton()"
                class="w-25 mx-auto"
              />
              <input
                id="dataset-input"
                class="no-display"
                type="file"
                name="file"
                accept=".zip"
                @change="uploadDataset"
              />

              <button
                v-if="showUploadButton()"
                class="action-btn btn-primary upload-btn"
                type="button"
                @click="selectDataset"
              >
                Upload Dataset
              </button>
              <div v-if="showSpinner()">
                <div class="row justify-content-center mt-4">
                  {{ uploadLabel }}

                  <span v-if="uploadProgress < 100.0" class="font-weight-bold">
                    {{ uploadProgress }}%</span
                  >
                </div>
                <i class="fas fa-spinner fa-pulse" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid mt-5" v-show="filteredItems.length" v-cloak>
      <div
        id="gallery"
        class="overview pl-2 pt-1"
        v-if="selectedEditItemIndex == -1"
      >
        <div class="my-4">
          <div
            :class="{
              gallery:
                dataset.content_type != utils.dataset_content_type_tabular
            }"
          >
            <div
              v-if="showPreviousPage"
              @click="changePage(false)"
              class="prev-img"
            >
              <i class="fas fa-chevron-left"></i>
            </div>
            <div
              v-if="dataset.content_type == utils.dataset_content_type_tabular"
            >
              <div
                class="container-fluid"
                v-show="filteredItems.length"
                v-cloak
              >
                <b-table
                  striped
                  hover
                  :fields="versionFields"
                  :items="filteredItems"
                  sort-icon-left
                >
                  <!--template v-slot:cell(edit)="row">
                <a
                  v-on:click.stop.prevent
                  @click="addOrEditVersion(row.item)"
                  class="btn"
                >
                  <i class="far fa-edit" />
                </a>
                </template-->
                  <template v-slot:cell(markdown)="row">
                    {{ utils.truncate(row.item["markdown"], 250) }}
                  </template>
                  <template v-slot:cell(delete)="row">
                    <a
                      v-on:click.stop.prevent
                      @click="startDelete(row.item, modal_state_item)"
                      class="btn"
                    >
                      <i class="far fa-trash-alt" />
                    </a>
                  </template>
                </b-table>
              </div>
            </div>
            <div
              v-else
              v-for="(item, index) in filteredItems"
              class="gallery-panel"
              :key="item.id"
              @click="editItem(item, index)"
            >
              <div
                :title="itemFilename(item)"
                v-if="
                  dataset.content_type == 'text' ||
                    dataset.content_type == 'ner'
                "
              >
                {{ item.text.substring(0, 200) }}
              </div>
              <div v-else>
                <img
                  :src="getImgUrl(item, true)"
                  :title="itemFilename(item)"
                  @error="getErrorImg($event, item)"
                />
              </div>
              <div class="overlay">
                <div v-if="dataset.multi_label" class="filename">
                  [ {{ itemAnnotationCount(item) }} ]
                </div>
                <div
                  v-if="
                    dataset.content_type == utils.dataset_content_type_text ||
                      dataset.content_type == utils.dataset_content_type_ner ||
                      dataset.content_type ==
                        utils.dataset_content_type_documents ||
                      dataset.multi_label
                  "
                  class="item-filename truncate-3"
                >
                  {{ itemFilename(item) }}
                </div>
                <div v-else>
                  <div
                    class="flex text-left"
                    v-if="item.annotations && item.annotations.length == 1"
                  >
                    <div class="vertical">
                      <div class="item-filename truncate-3">
                        <i
                          :style="
                            'color:' +
                              getLabelColor(item.annotations[0].label_id)
                          "
                          class="fa fa-square-full"
                        />
                        {{ getLabelName(item.annotations[0].label_id) }}
                      </div>
                      <!-- <div class="item-filename">
                        {{ itemFilename(item) }}
                      </div> -->
                    </div>
                  </div>
                </div>
                <a
                  class="item-delete"
                  v-if="ownsItem(dataset)"
                  @click.stop.prevent="startDelete(item, modal_state_item)"
                >
                  <i class="far fa-trash-alt" />
                </a>
              </div>
            </div>
            <div v-if="showNextPage" @click="changePage(true)" class="next-img">
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="image-detail mt-5" v-else>
        <div class="overview pl-2 pt-1">
          <div class="my-4">
            <form id="img-form">
              <div id="img-container">
                <a
                  v-if="showPreviousImage()"
                  @click="changeImage(false)"
                  class="prev-img"
                >
                  <i class="fas fa-chevron-left"></i>
                </a>
                <div
                  v-if="selectedEditItem.extension == 'txt'"
                  @mouseup="handleMouseUp"
                  @mousedown="handleMouseDown"
                  @keyup="handleMouseUp"
                >
                  <div
                    id="selected-edit-item-text"
                    class="show-breaks"
                    :title="itemFilename(selectedEditItem)"
                  >
                    {{ itemText }}
                  </div>
                </div>
                <div
                  v-else-if="
                    selectedEditItem.extension == 'doc' ||
                      selectedEditItem.extension == 'docx'
                  "
                >
                  <div :v-html="getWordFileContent(selectedEditItem)">
                    <div v-html="htmlContent"></div>
                  </div>
                </div>
                <div v-else-if="selectedEditItem.extension == 'pdf'">
                  <pdf
                    ref="pdfRef"
                    :src="getImgUrl(selectedEditItem, false)"
                    :page="pdfCurrentPage"
                    @num-pages="pdfNumpagesCb"
                  ></pdf>
                  <div>{{ pdfCurrentPage }} / {{ pdfNumPages }}</div>
                  <a
                    class="pdfPrevious"
                    v-if="pdfHasPreviousPage()"
                    @click.prevent.stop="pdfShowPreviousPage()"
                  >
                    <i class="fas fa-chevron-left"></i>
                  </a>
                  <a
                    class="pdfNext"
                    v-if="pdfHasNextPage()"
                    @click.prevent.stop="pdfShowNextPage()"
                  >
                    <i class="fas fa-chevron-right"></i>
                  </a>
                </div>
                <div
                  id="annotation-image-container"
                  v-bind:style="{
                    width: canvasWidth + 'px'
                  }"
                  v-else
                >
                  <img
                    id="annotation-image"
                    class="annotation-image"
                    :src="getImgUrl(selectedEditItem, false)"
                    :title="itemFilename(selectedEditItem)"
                    @load="onLoadImg"
                    @error="getErrorImg($event, selectedEditItem)"
                  />
                  <canvas
                    id="canvas"
                    v-if="dataset.multi_label"
                    :width="canvasWidth"
                    :height="canvasHeight"
                    @mousedown="handleMouseDown"
                    @mouseup="handleMouseUp"
                    @mouseout="handleMouseOut"
                    @mousemove="handleMouseMove"
                  />
                </div>
                <a
                  @click="handleItemEditOk"
                  class="close-image-detail"
                  v-if="showItemCloseDetail()"
                >
                  <i class="fas fa-times"></i>
                </a>
                <a
                  v-if="showNextImage()"
                  @click="changeImage(true)"
                  class="next-img"
                >
                  <i class="fas fa-chevron-right"></i>
                </a>
              </div>
              <div id="label-container">
                <div class="form-group" v-if="utils.canEdit(dataset)">
                  <multiselect
                    :options="filteredAnnotationLabels"
                    :close-on-select="true"
                    :preserve-search="true"
                    :internal-search="false"
                    :searchable="true"
                    placeholder="Labels..."
                    :show-labels="false"
                    @search-change="filterAnnotationLabels"
                    label="name"
                    @select="annotate"
                  >
                    <template slot="noResult">
                      <span>No label matches that search...</span>
                    </template>
                  </multiselect>
                </div>
                <div
                  v-for="annotation in selectedEditItem.annotations"
                  :key="annotation.id"
                  class="annotation"
                  @mouseenter="hoverOverAnnotation(annotation)"
                  @mouseleave="hoverOutAnnotation()"
                >
                  <div
                    v-if="showAnnotationSubtext(annotation)"
                    @click="editAnnotation(annotation)"
                    :class="{
                      annotation_error: annotation_errors.has(annotation.id)
                    }"
                    :title="
                      annotation_errors.has(annotation.id)
                        ? 'There is an overlapping annotation'
                        : getLabelName(annotation.label_id)
                    "
                  >
                    <!--  the title above is strictly for NER purposes, which is the only application that supports this right now. -->
                    <i
                      :style="'color:' + getLabelColor(annotation.label_id)"
                      class="fa fa-square-full"
                    />

                    <span
                      v-if="
                        dataset.content_type == utils.dataset_content_type_ner
                      "
                    >
                      {{ getNERAnnotationValue(annotation) }}
                    </span>
                    <span v-else>
                      {{ getLabelName(annotation.label_id) }}
                    </span>

                    <a
                      @click="startDelete(annotation, modal_state_annotation)"
                      class="annotation-delete"
                      v-if="utils.canEdit(dataset)"
                    >
                      <i class="far fa-trash-alt" />
                    </a>
                    <div class="subtext">
                      {{ annotationSubtext(annotation) }}
                    </div>
                  </div>
                  <div v-else>
                    <div class="form-group" v-if="utils.canEdit(dataset)">
                      <multiselect
                        v-model="selectedEditAnnotationLabel"
                        :options="filteredAnnotationLabels"
                        :close-on-select="true"
                        :preserve-search="true"
                        :internal-search="false"
                        :searchable="true"
                        placeholder="Labels..."
                        :show-labels="false"
                        @search-change="filterAnnotationLabels"
                        label="name"
                        :allowEmpty="false"
                        @select="editAnnotationLabel"
                      >
                        <template slot="noResult">
                          <span>No label matches that search...</span>
                        </template>
                      </multiselect>
                    </div>
                    <div
                      v-for="ai in annotation.annotation_inputs"
                      :key="ai.label"
                    >
                      <div class="font-weight-bold edit-annotation-label">
                        {{ ai.label }}:
                      </div>
                      <input
                        class="edit-annotation-input"
                        type="number"
                        v-model.number="ai.value"
                        @input="tweakAnnotation(annotation)"
                        @change="draw(false, false)"
                      />
                    </div>
                    <button
                      @click.stop.prevent="updateAnnotation(annotation)"
                      class="edit-annotation-btn btn-primary px-2"
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
              <div id="item-detail-footer" class="text-right">
                <span class="pr-3">{{ itemFilename(selectedEditItem) }}</span>
                <a
                  v-if="utils.canEdit(dataset)"
                  @click="startDelete(selectedEditItem, modal_state_item)"
                >
                  <i class="far fa-trash-alt" />
                </a>
                <hr />
                <div class="date">
                  Updated at: {{ utils.showDate(selectedEditItem.updated_at) }}
                </div>
                <div class="date">
                  Created at: {{ utils.showDate(selectedEditItem.created_at) }}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";
import pdf from "vue-pdf";

var defaultEntity = {
  name: ""
};

var defaultSelectedEditItem = { text: "", annotations: [] };
var defaultEditIndex = -1;

const sortOptions = [
  {
    label: "Sort items by: added - latest",
    value: "created_newest"
  },
  {
    label: "Sort items by: added -  oldest",
    value: "created_oldest"
  },
  {
    label: "Sort items by: updated - latest",
    value: "updated_newest"
  },
  {
    label: "Sort items by: updated - oldest",
    value: "updated_oldest"
  }
];

var copiedLabels = {
  version: "Version id copied to clipboard.",
  split: "Split id copied to clipboard.",
  dataset: "Dataset id copied clipboard.",
  label: "Label id copied to clipboard."
};

export default {
  name: "Dataset",
  components: {
    pdf
  },
  data: function() {
    return {
      dataset: {},
      versions: [],
      labels: [],
      filteredLabels: [],
      filteredAnnotationLabels: [],
      splits: [],
      items: [],
      selectedDefaultSplit: null,
      selectedSplit: null,
      selectedVersion: null,
      selectedLabel: {},
      selectedUploadLabel: {},
      startModelVersions: [],
      selectedStartModel: {},
      selectedStartModelVersion: {},
      selectedApplication: { continual_training: false },
      uploadLbl: "No file chosen",
      newLabel: "",
      search: "",
      canAddLabel: false,
      userId: "",
      selectedEditLabel: {},
      selectedEditLabelIndex: defaultEditIndex,
      nameState: null,
      selectedEditItem: defaultSelectedEditItem,
      selectedEditItemIndex: defaultEditIndex,
      onlyUnlabelled: false,
      labelSearch: "",
      isLabelSearchFocussed: false,
      trainLabel: "Train",
      pageCount: 0,
      pageSize: 32,
      labelStats: [],
      downloadVersionId: "",
      labelColors: [],
      versionToManage: {},
      newCleanVersion: false,
      manageVersionLabel: "Edit dataset version",
      manageSplitLabel: "Edit dataset split",
      splitToManage: {},
      imageCount: -1,
      isDown: false,
      startX: 0,
      startY: 0,
      offsetX: 0,
      offsetY: 0,
      canvasHeight: 150,
      canvasWidth: 300,
      canvas: undefined,
      ctx: undefined,
      dragTL: false,
      dragTR: false,
      dragBL: false,
      dragBR: false,
      closeEnough: 10,
      rect: {},
      mouseX: 0,
      mouseY: 0,
      annotationToUpdate: undefined,
      annotationsEditable: [],
      annotationsEditableTolerance: 5,
      hoverAnnotation: undefined,
      selected: {},
      options: [],
      selectedModel: {},
      entity: defaultEntity, // To contain entity to delete
      jobRequest: {},
      models: [],
      isUploading: false,
      sortOptions: sortOptions,
      selectedSort: {},
      versionFields: [{ key: "text" }],
      train_not_supported: false,
      utils: utils,
      datasetFormat: utils.dataset_format_folders,
      datasetFormatOptions: utils.dataset_formats,
      itemText: "",
      selectionColor: "#EAC400",
      selectedEditAnnotation: {},
      selectedEditAnnotationLabel: {},
      labelError: "",
      preventAnnotation: false,
      versionConfig: {},
      modal_state_version: "dataset_version",
      modal_state_split: "dataset_split",
      modal_state_label: "dataset_label",
      modal_state_annotation: "annotation",
      modal_state_item: "dataset_item",
      modal_state_empty: "",
      modalState: "",
      htmlContent: "",
      pdfCurrentPage: 1,
      pdfNumPages: 1,
      labelHeight: 100,
      sortLabelsIndex: 0,
      sortLabelsOptions: [
        utils.sort_option_alphabetically,
        utils.sort_option_reversed_alphabetically,
        utils.sort_option_index,
        utils.sort_option_count_high_low,
        utils.sort_option_count_low_high
      ],
      copyLabels: this.getDefaultCopyLabels(),
      uploadTips: [
        {
          name: "Splits",
          description:
            "Add a dataset split to manage your data by clicking on '+' in the top left.",
          img: "help/add-split-help.png",
          imgAlt: "add dataset split"
        },
        {
          name: "Labels",
          description:
            "Add labels to start annotating you data. Type the label name and hit 'enter' or the '+'.",
          img: "help/add-label-help.png",
          imgAlt: "add dataset label"
        },
        {
          name: "Items",
          description:
            "Add data to your dataset. Click the upload button next to 'Unlabelled'.",
          img: "help/add-item-help.png",
          imgAlt: "add data items"
        }
      ],
      letterKeys: [
        {
          keyCode: 65, // a
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 66, // b
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 67, // c
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 68, // d
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 69, // e
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 70, // f
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 71, // g
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 72, // h
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 73, // i
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 74, // j
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 75, // k
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 76, // l
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 77, // m
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 78, // n
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 79, // o
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 80, // p
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 81, // q
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 82, // r
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 83, // s
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 84, // t
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 85, // u
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 86, // v
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 87, // w
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 88, // x
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 89, // y
          modifiers: [],
          preventDefault: true
        },
        {
          keyCode: 90, // z
          modifiers: [],
          preventDefault: true
        }
      ],
      defaultColors: [
        "#098FCF",
        "#fd4d00",
        "#ffe53e",
        "#98fb00",
        "#d150ff",
        "#d57300",
        "#66C5FF",
        "#D3F5FF",
        "#9EADBD",
        "#E59C23",
        "#008A5B",
        "#FFECCC",
        "#CF4909",
        "#09CFAC",
        "#CF091F",
        "#9EADBD"
      ],
      uploadProgress: 0,
      uploadLabel: "Uploading your dataset: ",
      annotation_errors: new Set()
    };
  },
  computed: {
    filteredItems: function() {
      if (this.onlyUnlabelled) {
        return this.items.filter(item => {
          return (
            (item.annotations == null || item.annotations.length == 0) &&
            item.splits.find(s => {
              return s.id == this.selectedSplit.id;
            })
          );
        });
      }
      return this.items;
    },
    showPreviousPage: function() {
      return this.pageCount > 0;
    },
    showNextPage: function() {
      var count = 0;

      if (this.onlyUnlabelled) {
        count = this.getItemCount("unlabelled");
      } else if (this.selectedLabel.id) {
        // TODO: this is wrong, currently counts all annotations, instead of unique images in the selected verison for the selectedd label
        count = this.getItemCount(this.selectedLabel);
      } else {
        count = this.imageCount; //this.items ? this.items.length : 0;
      }

      return count > this.pageSize * (this.pageCount + 1);
    }
  },
  watch: {
    newLabel: function() {
      if (this.newLabel == "") {
        this.canAddLabel = false;
        return;
      }

      var labelNameFound = this.labels.find(lbl => {
        return lbl.name.toLowerCase() == this.newLabel.toLowerCase();
      });

      this.canAddLabel = !labelNameFound;
    },
    labelSearch: function() {
      this.filterListLabels(this.labelSearch);
    },
    selected: function() {
      this.selectedApplication = this.applications.find(a => {
        return a.id == this.selected.application_id;
      });
    },
    selectedStartModel: function() {
      this.$http
        .get(`${utils.modelApi}/${this.selectedStartModel.id}/versions`)
        .then(res => {
          this.startModelVersions = res.data.filter(v => {
            return v.application_id == this.selected.application_id;
          });
        });
    }
  },
  methods: {
    handleEsc: function() {
      // Leaving the comment, not sure why this was added once, but it introduces a bug that prevents using the ESC key.
      // if (document.activeElement) {
      //   document.activeElement.blur();
      //   return;
      // }
      if (this.rect && Object.keys(this.rect) != 0) {
        if (
          this.dataset.content_type == utils.dataset_content_type_images ||
          this.dataset.content_type == "ner"
        ) {
          this.draw(false, true);
        }

        return;
      }

      this.handleItemEditOk();
    },
    editAnnotation: function(annotation) {
      if (this.dataset.multi_label) {
        this.annotationValues(annotation);
        this.selectedEditAnnotation = annotation;

        this.selectedEditAnnotationLabel = this.labels.find(l => {
          return l.id == annotation.label_id;
        });
      }
    },
    tweakAnnotation: function(annotation) {
      if (this.dataset.content_type == utils.dataset_content_type_ner) {
        annotation.coordinates = `${annotation.annotation_inputs[0].value} ${annotation.annotation_inputs[1].value}`;
      } else if (
        this.dataset.content_type == utils.dataset_content_type_images &&
        this.dataset.multi_label
      ) {
        var new_rect = {
          x: annotation.annotation_inputs[0].value,
          y: annotation.annotation_inputs[1].value,
          w: annotation.annotation_inputs[2].value,
          h: annotation.annotation_inputs[3].value
        };
        annotation.coordinates = this.convertToYolo(new_rect);
      }
    },
    togglePreventAnnotation: function(prevent) {
      this.preventAnnotation = prevent;
    },
    handleKeyAnnotation: function(ev) {
      if (this.preventAnnotation) {
        return;
      }

      if (!this.selectedEditItem["id"]) {
        return;
      }

      var label = this.labels.find(l => {
        return l["shortcut"] == ev["event"]["key"];
      });

      if (label) {
        this.annotate(label);
      }
    },
    itemFilename: function(item) {
      if (item.name) {
        return `${item.name}.${item.extension}`;
      }

      return "";
    },
    itemAnnotationCount: function(item) {
      var annotationCount = 0;
      if (item.annotations && item.annotations.length > 0) {
        annotationCount = item.annotations.length;
      }

      return annotationCount;
    },
    checkLabelShortcut: function() {
      this.labelError = "";
      var found = this.labels.find(l => {
        return (
          l["shortcut"].length > 0 &&
          l["shortcut"] == this.selectedEditLabel["shortcut"] &&
          l["id"] != this.selectedEditLabel["id"]
        );
      });
      if (found) {
        const shortcutCopy = found["shortcut"].slice();
        this.labelError = `Shortcut '${shortcutCopy}' is already in use by '${found.name}'`;
        this.selectedEditLabel["shortcut"] = "";
        return;
      }
    },
    labelname: function(label) {
      if (label.shortcut.length > 0) {
        return `${label.name} [${label.shortcut}]`;
      }

      if (label && label.name) {
        return label.name;
      }

      return "";
    },
    showAnnotationSubtext: function(annotation) {
      return annotation.id != this.selectedEditAnnotation.id;
    },
    getColumnNames: function() {
      return this.versionFields
        .filter(field => field.key !== "Delete")
        .map(field => field.key);
    },
    getDataset: function() {
      this.$http
        .get(utils.datasetsApi + "/" + this.$route.params.id)
        .then(response => {
          this.dataset = response.data;

          this.versions = this.dataset.versions;
          // remove hardcoded 0 index when you can select ≠ versions
          // Expand the API with active version

          let version_id = this.$route.params.version_id;

          let version = undefined;

          if (version_id) {
            version = this.versions.find(v => {
              return v.id == version_id;
            });
          }

          if (!version) {
            version = this.versions[0];
          }

          if (version) {
            this.initVersion(version);
          }

          if (this.dataset.multi_label) {
            this.sortLabelsOptions.push(utils.sort_option_annotation_high_low);
            this.sortLabelsOptions.push(utils.sort_option_annotation_low_high);
          }

          this.jobRequest = this.getDefaultJobRequest();
          this.getApplications();
        })
        .catch(err => {
          if (err.status == this.utils.http_status_not_found) {
            this.$router.push({
              name: "NotFound"
            });
          } else {
            this.$router.push({
              name: "Login",
              query: { redirect: this.$route.fullPath }
            });
          }
        });
    },
    getWordFileContent: function(item) {
      let url = this.getImgUrl(item, false);

      this.$http.get(url, { responseType: "arraybuffer" }).then(async res => {
        try {
          // Load mammoth.js dynamically
          if (!window.mammoth) {
            await this.loadMammothJS();
          }

          const docxBuffer = res.data;

          window.mammoth
            .convertToHtml({ arrayBuffer: docxBuffer })
            .then(result => {
              this.htmlContent = result.value;
            })
            .catch(error => {
              throw error;
            });
        } catch (err) {
          this.error = `Error processing document: ${err.message}`;
          this.content = "";
        } finally {
          this.loading = false;
        }
      });
    },
    loadMammothJS: function() {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src =
          "https://cdnjs.cloudflare.com/ajax/libs/mammoth/1.6.0/mammoth.browser.min.js";
        script.async = true;

        script.onload = resolve;
        script.onerror = () => reject(new Error("Failed to load Mammoth.js"));

        document.body.appendChild(script);
      });
    },
    getSupportedMIMEtypes: function() {
      let mime_type = "";

      switch (this.dataset.content_type) {
        case this.utils.dataset_content_type_images:
          mime_type = `${this.utils.file_type_png}, ${this.utils.file_type_jpg}, ${this.utils.file_type_jpeg}`;
          break;
        case this.utils.dataset_content_type_ner:
          mime_type = this.utils.file_type_text_plain;
          break;
        case this.utils.dataset_content_type_text:
          mime_type = this.utils.file_type_text_plain;
          break;
        case this.utils.dataset_content_type_documents:
          mime_type = `${this.utils.file_type_pdf}, ${this.utils.file_type_doc}, ${this.utils.file_type_docx}, ${this.utils.file_type_text_plain}, ${this.utils.file_type_jpeg}, ${this.utils.file_type_jpg}, ${this.utils.file_type_png}`;
          break;
      }
      return mime_type;
    },
    uploadDataset: function() {
      let datasetForm = utils.el("dataset-input-form");
      var uploadFiles = utils.el("dataset-input").files;
      var formData = new FormData();

      if (this.isUploading) {
        return;
      }

      this.isUploading = true;

      formData.append("file", uploadFiles[0]);

      var self = this;

      this.$http
        .post(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/upload/${this.datasetFormat}`,
          formData,
          {
            progress(e) {
              if (e.lengthComputable) {
                self.uploadProgress = Math.round((e.loaded / e.total) * 100);

                if (self.uploadProgress == 100.0) {
                  self.uploadLabel =
                    "Processing your dataset... Depending on its size, this can take a while.";
                }
              }
            }
          }
        )
        .then(() => {
          this.isUploading = false;
          datasetForm.reset();
          this.getDataset();
        })
        .catch(() => {
          datasetForm.reset();
          this.isUploading = false;
        });
    },
    selectDataset: function() {
      utils.el("dataset-input").click();
    },
    showItemCloseDetail: function() {
      return !this.isDown;
    },
    showPreviousImage: function() {
      if (this.isDown) {
        return false;
      }

      if (this.pageCount == 0 && this.selectedEditItemIndex == 0) {
        return false;
      }

      return true;
    },
    showNextImage: function() {
      if (this.isDown) {
        return false;
      }
      var count = 0;

      if (this.onlyUnlabelled) {
        count = this.getItemCount("unlabelled");
      } else if (this.selectedLabel.id) {
        // TODO: this is wrong, currently counts all annotations, instead of unique images in the selected verison for the selectedd label
        count = this.getItemCount(this.selectedLabel);
      } else {
        count = this.imageCount; //this.items ? this.items.length : 0;
      }

      return (
        count > this.pageSize * this.pageCount + this.selectedEditItemIndex + 1
      );
    },
    modalOkDisabled: function(entity) {
      if (!entity.name > 0 || entity.name.length == 0) {
        return true;
      }

      return false;
    },
    selectSort: function(sortOption) {
      this.selectedSort = sortOption;
      this.getItems();
    },
    getItems: function(cb, args, keep_selected_item) {
      this.$http
        .get(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/items?pageCount=${this.pageCount}&pageSize=${this.pageSize}&labelId=${this.selectedLabel.id}&splitId=${this.selectedSplit.id}&onlyUnlabelled=${this.onlyUnlabelled}&sort=${this.selectedSort["value"]}&itemId=${this.selectedEditItem.id}`
        )
        .then(res => {
          if (this.dataset.content_type == utils.dataset_content_type_tabular) {
            var csv_separator = utils.csv_default_separator;
            if (utils.csv_separator_key in this.versionConfig) {
              csv_separator = this.versionConfig[utils.csv_separator_key];
            }
            res.data.forEach(data_item => {
              var values = data_item.text.split(csv_separator);
              this.versionFields.forEach((field, index) => {
                data_item[field["key"]] = values[index];
              });
            });
          }

          this.items = res.data;

          if (!keep_selected_item) {
            this.selectedEditItem = defaultSelectedEditItem;
            this.selectedEditItemIndex = defaultEditIndex;
          }

          this.getLabelStats();

          this.getTotalItemCount();

          if (cb) {
            cb(args);
          }
        });
    },
    getTotalItemCount() {
      if (!(this.selectedSplit && this.selectedSplit.id)) {
        return;
      }

      var entityID = this.selectedSplit.id;

      var url = `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/labels/stats/splits/${entityID}`;

      this.$http.get(url).then(res => {
        this.imageCount = res.data.item_count;
      });
    },
    addLabel: function() {
      if (!this.canAddLabel) {
        return;
      }

      var labelLength = this.labels.length;

      var color = utils.random_color();

      if (labelLength < this.defaultColors.length) {
        color = this.defaultColors[labelLength];
      }

      var label = {
        name: this.newLabel,
        color: color
      };

      this.$http
        .post(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/labels`,
          label
        )
        .then(() => {
          this.refreshLabels();
        });
    },
    canDelete: function(entity) {
      var user_id = utils.user_id();
      // Owner can delete
      if (this.dataset.user_id == user_id) {
        return true;
      }

      // You can delete your own shares
      if (entity.user_id == user_id) {
        return true;
      }

      return false;
    },
    manageVersion: function(version, isNew) {
      if (isNew) {
        this.manageVersionLabel = "New dataset version";
      } else {
        this.manageVersionLabel = "Edit dataset version";
      }
      this.versionToManage = version;
      this.$bvModal.show("manage-version");
    },
    isDownloadVersion: function(version) {
      return version && version.id == this.downloadVersionId;
    },
    downloadVersion: function(version) {
      this.downloadVersionId = version.id;

      let downloadUrl = `${utils.datasetsApi}/${version["dataset_id"]}/versions/${version["id"]}/download?idmap=true`;

      this.$http.get(downloadUrl, { responseType: "blob" }).then(res => {
        const url = window.URL.createObjectURL(res.bodyBlob);
        const link = document.createElement("a");
        link.href = url;
        const assetType = "zip";
        link.setAttribute(
          "download",
          `${this.dataset.name}-${version.name}-${version.id}.${assetType}`
        );
        document.body.appendChild(link);
        link.click();
        this.downloadVersionId = "";
        setTimeout(function() {
          URL.revokeObjectURL(link.href);
        }, 2000);
      });
    },
    addVersion: function() {
      // We update the default_split
      // We use the selectedDefaultSplit to display the label of the selectedSplit. If we modify the value, we need to update it on the versionToManage as well.
      if (this.selectedDefaultSplit && this.selectedDefaultSplit.id) {
        this.versionToManage.default_split = this.selectedDefaultSplit.id;
      }

      var url = `${utils.datasetsApi}/${this.dataset.id}/versions/${this.versionToManage.id}`;
      var method = "put";

      if (!this.versionToManage.created_at) {
        method = "post";

        if (this.newCleanVersion) {
          url = `${utils.datasetsApi}/${this.dataset.id}/versions`;
        } else {
          url = `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/new`;
        }
      }

      this.$http[method](url, this.versionToManage).then(() => {
        if (method == "post") {
          this.versionToManage = {};

          this.getDataset();
        }

        this.$bvModal.hide("manage-version");
      });
    },
    toggleNewCleanVersion: function() {
      this.newCleanVersion = !this.newCleanVersion;
    },
    startDelete: function(entity, state) {
      this.modalState = state;
      this.entity = entity;
      this.$bvModal.show("simple-modal");
    },
    doDelete: function() {
      switch (this.modalState) {
        case this.modal_state_version:
          this.deleteVersion(this.entity);
          break;
        case this.modal_state_split:
          this.deleteSplit(this.entity);
          break;
        case this.modal_state_label:
          this.deleteLabel(this.entity);
          break;
        case this.modal_state_annotation:
          this.deleteAnnotation(this.selectedEditItem, this.entity);
          break;
        case this.modal_state_item:
          this.deleteItem(this.entity);
          break;
      }
    },
    deleteVersion: function(version) {
      this.versions = this.versions.filter(v => {
        return v.id != version.id;
      });

      if (this.selectedVersion == version) {
        this.selectedVersion = this.versions[0] || {};
        this.getDataset();
      }
      this.$http
        .delete(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${version.id}`
        )
        .then(() => {
          this.modalState = this.modal_state_empty;
          this.getDataset();
        });
    },
    deleteSplit: function(split) {
      this.$http
        .delete(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/splits/${split.id}`
        )
        .then(() => {
          this.modalState = this.modal_state_empty;

          this.splits = this.splits.filter(s => {
            return s.id != split.id;
          });
          if (this.selectedSplit == split) {
            this.selectedSplit = this.splits[0] || {};
            this.getDataset();
          }
        });
    },
    manageSplit: function(split, isNew) {
      if (isNew) {
        this.manageSplitLabel = "New dataset split";
      } else {
        this.manageSplitLabel = "Edit dataset split";
      }
      this.splitToManage = split;
      this.$bvModal.show("manage-split");
    },
    refreshLabels: function() {
      this.$http
        .get(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/labels`
        )
        .then(res => {
          this.selectedVersion.labels = res.data;

          this.initLabels(res.data);

          this.getLabelStats();
        });
    },
    deleteLabel: function(label) {
      this.$http
        .delete(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/labels/${label.id}`
        )
        .then(() => {
          this.modalState = this.modal_state_empty;
          if (label.id == this.selectedLabel.id) {
            this.selectedLabel = {};
          }

          this.getItems();
          this.getLabelStats();

          this.refreshLabels();
        });
    },
    deleteItem: function(item) {
      this.$http
        .delete(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/splits/${this.selectedSplit.id}/items/${item.id}`
        )
        .then(() => {
          this.modalState = this.modal_state_empty;
          this.getItems();
        });
    },
    canAddItem: function() {
      if (!this.utils.canEdit(this.dataset)) {
        return false;
      }

      if (this.splits.length > 0) {
        return true;
      }

      return false;
    },
    startUpload: function() {
      var upload_files = utils.el("add-item").files;

      var self = this;

      var upload_files_amount = upload_files.length;

      const max_items = 100;

      if (upload_files_amount > max_items) {
        return alert(
          `Max ${max_items} files at once. You selected ${upload_files_amount} files.`
        );
      }

      Array.prototype.forEach.call(upload_files, function(image, index) {
        var item = {
          text: image.name,
          splits: [self.selectedSplit] // Keep this in for now
        };

        self.$http
          .post(
            `${utils.datasetsApi}/${self.dataset.id}/versions/${self.selectedVersion.id}/items`,
            item
          )
          .then(res => {
            var created_item = res.data;
            var formData = new FormData();
            formData.append("file", image);
            self.$http
              .post(
                `${utils.datasetsApi}/${self.dataset.id}/versions/${self.selectedVersion.id}/items/${created_item.id}/upload`,
                formData
              )
              .then(() => {
                if (self.selectedUploadLabel) {
                  var annotation = {
                    split_id: self.selectedSplit.id,
                    user_id: utils.user_id(),
                    item_id: created_item.id
                  };

                  self.labelStats[self.selectedUploadLabel.id].item_count += 1;
                  annotation.label_id = self.selectedUploadLabel.id;

                  self.$http.post(
                    `${utils.datasetsApi}/${self.dataset.id}/versions/${self.selectedVersion.id}/annotations`,
                    annotation
                  );
                }

                if (index == upload_files_amount - 1) {
                  self.getItems();
                }
              })
              .catch(() => {
                if (index == upload_files_amount - 1) {
                  self.getItems();
                }
              });
          });
      });
    },
    selectLabel: function(label) {
      let new_url = `/datasets/${this.dataset.id}/versions/${this.selectedVersion.id}/splits/${this.selectedSplit.id}`;

      this.onlyUnlabelled = false;

      this.clearSelectEditItem();
      this.pageCount = 0;
      if (
        this.selectedLabel.id == undefined ||
        this.selectedLabel.id != label.id
      ) {
        this.selectedLabel = label;
        new_url = `${new_url}/labels/${this.selectedLabel.id}`;
      } else {
        this.selectedLabel = {};
      }

      //this.$router.replace(new_url);
      this.pushUrl(new_url);
      this.getItems();
    },
    selectImagesForLabel: function(label) {
      this.onlyUnlabelled = false;
      // selectedLabel should be for filtereing?
      this.selectedUploadLabel = label;
      utils.el("add-item").click();
    },
    getErrorImg: function(per, item) {
      if (item) {
        if (
          item.extension == this.utils.file_extension_docx ||
          item.extension == this.utils.file_extension_doc
        ) {
          per.srcElement.src = require("../img/word-icon.png");
          return;
        }

        if (item.extension == this.utils.file_extension_pdf) {
          per.srcElement.src = require("../img/pdf-icon.png");
          return;
        }
      }

      per.srcElement.src = require("../img/seeme-ai-logo-waiting-for.png");
    },
    getImgUrl: function(item, getThumbnail) {
      var url = `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/items/${item.id}/download`;

      if (getThumbnail) {
        url += "?thumbnail=true";
      }

      return url;
    },
    pdfNumpagesCb: function(numPages) {
      this.pdfNumPages = numPages;
    },
    onLoadImg: function() {
      if (this.dataset.content_type == this.utils.dataset_content_type_images) {
        this.initCanvas();
      }
      this.draw(false, true);
    },
    getUserId: function() {
      this.user_id = localStorage.getItem("user_id");
    },
    ownsItem: function(item) {
      return item.user_id == this.user_id;
    },
    resetModal(bvModalEvt) {
      this.togglePreventAnnotation(bvModalEvt["type"] == "show");

      this.nameState = null;
    },
    getModalTitle: function() {
      return `Delete ${this.modalState.replace("_", " ")}`;
    },
    getModalName: function() {
      if (this.modalState == this.modal_state_annotation) {
        var label = this.labels.find(l => {
          return l.id == this.entity.label_id;
        });

        if (label) {
          return label.name;
        }

        return "annotation";
      }
      return this.entity.name;
    },
    cancelDelete: function() {
      this.entity = defaultEntity;
      this.modalState = this.modal_state_empty;
    },
    handleItemEditOk() {
      if (this.preventAnnotation) {
        return;
      }
      this.clearSelectEditItem();
    },
    addOrUpdateSplit: function() {
      var url = `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/splits`;
      var method = "post";

      if (this.splitToManage.created_at) {
        url = `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/splits/${this.splitToManage.id}`;
        method = "put";
      }

      this.$http[method](url, this.splitToManage).then(res => {
        if (method == "post") {
          this.selectedSplit = res.data;
          this.splits.push(this.selectedSplit);

          this.splitToManage = {};
        }

        this.$bvModal.hide("manage-split");
      });
    },
    updateLabel() {
      this.labelError = "";

      this.$http
        .put(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/labels/${this.selectedEditLabel.id}`,
          this.selectedEditLabel
        )
        .then(res => {
          this.$bvModal.hide("edit-label");
          this.labels[this.selectedEditLabelIndex] = res.body;
          this.labelColors[this.selectedEditLabel.id] = res.body.color;

          // this.selectedEditLabel = {};
          // this.selectedEditLabelIndex = -1;

          if (this.dataset.multi_label) {
            this.draw(false);
          }
        });
    },
    disabledTrainOk: function() {
      if (!this.jobRequest.name || this.jobRequest.name.length == 0) {
        return true;
      }

      return false;
    },
    handleAddTrain: function() {
      this.jobRequest.dataset_id = this.selectedVersion["dataset_id"];
      this.jobRequest.dataset_version_id = this.selectedVersion["id"];

      this.jobRequest.application_id = this.selected.application_id;

      this.jobRequest.items = this.selected.items;
      if (this.selectedModel.id) {
        this.jobRequest.model_id = this.selectedModel.id;
      }

      if (this.selectedStartModel && this.selectedStartModel.id) {
        this.jobRequest.start_model_id = this.selectedStartModel.id;
      }

      if (this.selectedStartModelVersion && this.selectedStartModelVersion.id) {
        this.jobRequest.start_model_version_id = this.selectedStartModelVersion.id;
      }

      this.jobRequest.items.forEach(i => {
        i.value = String(i.value);
      });
      this.$http.post(`${utils.jobsApi}`, this.jobRequest).then(() => {
        this.selectedModel = {};
        this.jobRequest = this.getDefaultJobRequest();
        this.selectedStartModel = {};
        this.selectedStartModelVersion = {};
      });
    },
    getDefaultJobRequest: function() {
      var job_req = {
        name: `${this.dataset.name}`,
        job_type: utils.job_type_training,
        items: []
      };

      return job_req;
    },
    editLabel: function(label, index) {
      this.selectedEditLabel = label;
      this.selectedEditLabelIndex = index;

      this.$bvModal.show("edit-label");
    },
    checkAnnotationErrors: function() {
      if (this.dataset.content_type == "ner") {
        this.draw(false);

        if (this.selectedEditItem.annotations) {
          this.annotation_errors = new Set();

          var count = 1;

          this.selectedEditItem.annotations.forEach(a => {
            var a_coords = utils.nerCoordinates(a.coordinates);
            var s_s = a_coords[0];
            var s_e = a_coords[1];

            var error = this.selectedEditItem.annotations
              .slice(count)
              .find(as => {
                var coords = utils.nerCoordinates(as.coordinates);

                var a_s = coords[0];
                // var a_e = coords[1];

                return s_s <= a_s && s_e >= a_s;

                // return a_s >= s_s && s_s <= a_e;
              });
            count += 1;

            if (error) {
              this.annotation_errors.add(a.id);
              this.annotation_errors.add(error.id);
            }
          });
        }
      }
    },
    editItem: function(item, index) {
      this.selectedEditItem = item;
      if (!this.selectedEditItem.annotations) {
        this.selectedEditItem.annotations = [];
      }

      this.selectedEditItemIndex = index;
      this.itemText = item.text;

      let new_url = `/datasets/${this.dataset.id}/versions/${this.selectedVersion.id}/splits/${this.selectedSplit.id}`;
      if (this.selectedLabel && this.selectedLabel.id) {
        new_url = `${new_url}/labels/${this.selectedLabel.id}`;
      }

      new_url = `${new_url}/items/${this.selectedEditItem.id}`;
      this.pushUrl(new_url);

      this.checkAnnotationErrors();
      setTimeout(this.initCanvas, 100);

      if (this.dataset.content_type == "ner") {
        setTimeout(() => {
          this.draw(false, false);
        }, 1000);
      }
    },
    initCanvas: function() {
      this.canvas = document.getElementById("canvas");

      var img = utils.el("annotation-image");

      if (img && img.naturalWidth > 0) {
        var imgContainer = utils.el("img-container");

        // Resize the canvas to match the image

        // Images SMALLER than the img container size: maxWidth = img.naturalWidth; heightFactor = 1;
        // ==> Keep the annotations on the correct position

        // Images LARGER than the img container size: maxWidht = imgContainer.clientWidth; heightFactor < 1;
        // ==> Keep the image within the img container

        var maxWidth = Math.min(imgContainer.clientWidth, img.naturalWidth);
        var heightFactor = maxWidth / img.naturalWidth;

        this.canvasHeight = Math.min(img.naturalHeight * heightFactor);
        this.canvasWidth = maxWidth;

        if (this.canvas) {
          this.ctx = this.canvas.getContext("2d");

          var canvasOffset = this.canvas.getBoundingClientRect();

          this.offsetX = canvasOffset.left;
          this.offsetY = canvasOffset.top;
          this.draw();
        }

        // If we draw directly, the canvasHeight/canvasWidth has is not yet updated in the canvas
        setTimeout(this.draw, 100);
      }
    },
    getLabelName: function(label_id) {
      var label = this.labels.find(l => {
        return l.id == label_id;
      });

      if (label && label.name) {
        return label.name;
      }

      return "";
    },
    getNERAnnotationValue: function(annot) {
      let split = annot.coordinates.split(" ");
      let start = parseInt(split[0]);
      let end = parseInt(split[1]);
      return this.selectedEditItem.text.substring(start, end);
    },
    getDefaultCopyLabels: function() {
      return {
        version: "Copy version id to clipboard",
        split: "Copy split id to clipboard",
        dataset: "Copy dataset id to clipboard",
        label: "Copy label id to clipboard"
      };
    },
    resetCopyLabel: function() {
      setTimeout(() => {
        this.copyLabels = this.getDefaultCopyLabels(); //defaultCopyLabels[for_label];
      }, 500);
    },
    getCopyLabel: function(for_label) {
      return this.copyLabels[for_label];
    },
    copyToClipboard: function(text, for_label) {
      this.utils.copyToClipboard(text);

      this.copyLabels[for_label] = copiedLabels[for_label];
    },
    hoverOverAnnotation: function(annotation) {
      if (this.dataset.multi_label) {
        this.hoverAnnotation = annotation;
        this.draw(false);
      }
    },
    hoverOutAnnotation: function() {
      if (this.dataset.multi_label) {
        this.hoverAnnotation = undefined;
        this.draw(false);
      }
    },
    deleteAnnotation: function(item, annotation) {
      item.annotations = item.annotations.filter(a => {
        return a.id != annotation.id;
      });

      this.$http
        .delete(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/annotations/${annotation.id}`
        )
        .then(() => {
          if (this.dataset.multi_label) {
            this.draw(false);
          }
          this.getItems(undefined, undefined, true);

          this.modalState = this.modal_state_empty;

          this.checkAnnotationErrors();
        });
    },
    updateItem: function(item, cb) {
      this.$http
        .put(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/items/${item.id}`,
          item
        )
        .then(res => {
          this.selectedEditItem = res.body;

          this.checkAnnotationErrors();

          if (cb != undefined) {
            cb();
          }
        });
    },
    showUnlabelled: function() {
      this.pageCount = 0;
      this.selectedLabel = {};
      this.selectedUploadLabel = undefined;
      this.onlyUnlabelled = !this.onlyUnlabelled;
      this.clearSelectEditItem();
      this.getItems();
    },
    showUploadButton: function() {
      return !this.isUploading && this.splits.length == 0;
    },
    showSpinner: function() {
      return this.isUploading;
    },
    selectUnlabelledImages: function() {
      this.selectedUploadLabel = undefined;
      utils.el("add-item").click();
    },
    labelVisible: function(label, search) {
      if (search.length < 2) {
        return true;
      }

      let labelName = label.name.toLowerCase();
      let currentFilter = search.toLowerCase();
      return labelName.includes(currentFilter);
    },
    filterAnnotationLabels(search) {
      this.filterLabels(this.filteredAnnotationLabels, search, false);
    },
    filterListLabels(search) {
      this.filterLabels(this.filteredLabels, search, true);
    },
    filterLabels: function(labels, search, list_labels) {
      labels = this.labels.filter(l => {
        return this.labelVisible(l, search);
      });

      if (list_labels) {
        this.filteredLabels = labels;
      } else {
        this.filteredAnnotationLabels = labels;
      }
    },
    labelSearchFocussed: function(isFocussed) {
      this.isLabelSearchFocussed = isFocussed;
      this.togglePreventAnnotation(isFocussed);
    },
    getSelectionOffsetRelativeTo: function(parentElement, currentNode) {
      var currentSelection,
        currentRange,
        offset = 0,
        endOffset = 0,
        prevSibling,
        nodeContent;

      if (!currentNode) {
        currentSelection = window.getSelection();

        currentRange = currentSelection.getRangeAt(0);

        if (currentRange.startContainer == currentRange.endContainer) {
          currentNode = currentRange.startContainer;
          offset += currentRange.startOffset;

          endOffset = currentRange.endOffset - currentRange.startOffset;
        } else {
          return -1;
        }
      }

      if (currentNode === parentElement) {
        return [offset, endOffset];
      }

      if (!parentElement.contains(currentNode)) {
        return -1;
      }

      while ((prevSibling = (prevSibling || currentNode).previousSibling)) {
        nodeContent = prevSibling.innerText || prevSibling.nodeValue || "";
        offset += nodeContent.length;
      }

      return [
        offset +
          this.getSelectionOffsetRelativeTo(
            parentElement,
            currentNode.parentNode
          )[0],
        endOffset
      ];
    },
    updateAnnotation: function(annotation) {
      let annotationsUrl = `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/annotations/${annotation.id}`;

      this.$http.put(annotationsUrl, annotation).then(() => {
        this.selectedEditAnnotation = {};
        this.getLabelStats();
        if (this.dataset.multi_label) {
          this.draw(false, false);
        }

        this.checkAnnotationErrors();
      });

      this.annotationToUpdate = undefined;
    },
    editAnnotationLabel: function(ann) {
      this.selectedEditAnnotationLabel = ann;

      this.selectedEditAnnotation.label_id = ann.id;
    },
    annotate: function(label) {
      // TODO: if not multiple: delete and post, or update current?
      var annotation = {};
      var item = this.selectedEditItem;
      annotation.label_id = label.id;
      annotation.item_id = item.id;
      annotation.split_id = this.selectedSplit.id;

      if (!item.annotations) {
        item.annotations = [];
      }

      this.labelSearch = "";

      if (this.dataset.multi_label) {
        if (this.dataset.content_type == utils.dataset_content_type_images) {
          if (!(this.rect && Object.keys(this.rect) != 0)) {
            return;
          }

          annotation.coordinates = this.convertToYolo(this.rect);

          this.draw(false, true);

          this.rect = {};
        } else if (this.dataset.content_type == "ner") {
          if (!(this.rect && Object.keys(this.rect) != 0)) {
            return;
          }

          annotation.coordinates = `${this.rect.startOffset} ${this.rect.endOffset}`;

          this.draw(false, true);
        }

        this.$http
          .post(
            `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/annotations`,
            annotation
          )
          .then(res => {
            item.annotations.push(res.data);
            this.getLabelStats();
            this.draw(false, true);
          });
      } else {
        if (item.annotations.length == 0) {
          this.$http
            .post(
              `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/annotations`,
              annotation
            )
            .then(res => {
              item.annotations.push(res.data);
              // Do we need to get them?
              this.getItems(undefined, undefined, true);
            });
        } else {
          var ann = item.annotations.find(a => {
            return a.split_id == this.selectedSplit.id;
          });

          if (ann) {
            this.$http
              .delete(
                `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/annotations/${ann.id}`
              )
              .then(() => {
                item.annotations = item.annotations.filter(a => {
                  return a.id != ann.id;
                });
                this.$http
                  .post(
                    `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/annotations`,
                    annotation
                  )
                  .then(res => {
                    item.annotations.push(res.data);
                    this.getLabelStats();
                    // DO we need to get them?
                    //this.getItems(undefined, undefined, true);
                  });
              });
          }
        }
      }
    },
    getTipOpacity: function(tipName) {
      if (tipName == "Splits" && this.splits.length > 0) {
        return "opacity-2";
      }

      if (tipName == "Labels" && this.labels.length > 0) {
        return "opacity-2";
      }

      return "opacity-5";
    },
    getLabelStat: function(label, annotation_or_item_count) {
      var propertyID = label == "unlabelled" ? label : label.id;

      if (
        this.labelStats &&
        this.labelStats[propertyID] &&
        this.labelStats[propertyID][annotation_or_item_count]
      ) {
        return this.labelStats[propertyID][annotation_or_item_count];
      }

      return 0;
    },
    getItemCount: function(label) {
      return this.getLabelStat(label, "item_count");
    },
    getAnnotationCount: function(label) {
      return this.getLabelStat(label, "annotation_count");
    },
    getLabelColor: function(label) {
      var label_id = label.id || label;
      return this.labelColors[label_id];
    },
    getLabelStatsTitle: function(label) {
      let annotationCount = this.getAnnotationCount(label);
      let annotationLabel = annotationCount == 1 ? "annotation" : "annotations";

      let itemCount = this.getItemCount(label);
      let itemLabel = itemCount == 1 ? "item" : "items";

      return `${annotationCount} ${annotationLabel} - ${itemCount} unique ${itemLabel}`;
    },
    // automatically gets called after getItems();
    getLabelStats: function() {
      if (
        !(
          this.selectedVersion &&
          this.selectedVersion.id &&
          this.selectedSplit &&
          this.selectedSplit.id
        )
      ) {
        return;
      }
      this.$http
        .get(
          `${utils.datasetsApi}/${this.dataset.id}/versions/${this.selectedVersion.id}/labels/splits/${this.selectedSplit.id}`
        )
        .then(res => {
          this.labelStats = this.processLabelStats(res.data);
        });
    },
    processLabelStats: function(labelStats) {
      var arrayOfStats = [];

      labelStats.forEach(l => {
        var propertyName = l.label_id;

        if (l.label_id == "") {
          propertyName = "unlabelled";
        }

        arrayOfStats[propertyName] = l;
      });

      return arrayOfStats;
    },
    getSelectionLabel: function() {
      return "OK";
    },
    trainVersion: function() {
      this.getApplications(() => {
        if (
          this.dataset.multi_label &&
          this.dataset.content_type == utils.dataset_content_type_images
        ) {
          // Add application ID for OD

          var application_id = utils.getApplicationId(
            "yolo",
            "v4",
            "",
            "",
            "object_detection"
          );

          var objectDetectionOptions = [
            {
              application_id: application_id,
              name: "Yolo v4",
              items: [
                {
                  label: "Architecture",
                  name: "arch",
                  value: "yolov4",
                  value_type: utils.job_value_type_text,
                  hidden: true
                },
                {
                  label: "Image size",
                  name: "image_size",
                  value: "512",
                  value_type: utils.job_value_type_number
                },
                {
                  label: "Batch size",
                  name: "batch_size",
                  value: "64",
                  value_type: utils.job_value_type_number
                },
                {
                  label: "Subdivisions",
                  name: "subdivisions",
                  value: "32",
                  value_type: utils.job_value_type_number
                }
              ]
            },
            {
              application_id: application_id,
              name: "Yolo v4 Tiny",
              items: [
                {
                  label: "Architecture",
                  name: "arch",
                  value: "yolov4-tiny",
                  value_type: utils.job_value_type_text,
                  hidden: true
                },
                {
                  label: "Image size",
                  name: "image_size",
                  value: "416",
                  value_type: utils.job_value_type_number
                },
                {
                  label: "Batch size",
                  name: "batch_size",
                  value: "64",
                  value_type: utils.job_value_type_number
                },
                {
                  label: "Subdivisions",
                  name: "subdivisions",
                  value: "8",
                  value_type: utils.job_value_type_number
                }
              ]
            }
          ];

          this.options = objectDetectionOptions;
          this.selected = this.options[1];
        } else if (this.dataset.content_type == "text") {
          application_id = utils.getApplicationId(
            "pytorch",
            "1.12.0",
            "fastai",
            "2.7.9",
            "text_classification"
          );

          var textClassificationOptions = [
            {
              application_id: application_id,
              name: "AWD LSTM",
              arch: "AWD_LSTM"
            }
          ];

          this.options = textClassificationOptions;
          this.selected = this.options[0];
        } else if (
          this.dataset.content_type == utils.dataset_content_type_images
        ) {
          var application_id_pt_2_0_1_fa_2_7_12 = utils.getApplicationId(
            "pytorch",
            "2.0.1",
            "fastai",
            "2.7.12",
            "image_classification"
          );

          var application_id_pt_2_1_2_fa_2_7_13 = utils.getApplicationId(
            "pytorch",
            "2.1.2",
            "fastai",
            "2.7.13",
            "image_classification"
          );

          var application_id_pt_2_2_0_fa_2_7_14 = utils.getApplicationId(
            "pytorch",
            "2.2.0",
            "fastai",
            "2.7.14",
            "image_classification"
          );

          var application_id_pt_2_2_2_fa_2_7_14 = utils.getApplicationId(
            "pytorch",
            "2.2.2",
            "fastai",
            "2.7.14",
            "image_classification"
          );
          var application_id_pt_2_3_0_fa_2_7_15 = utils.getApplicationId(
            "pytorch",
            "2.3.0",
            "fastai",
            "2.7.15",
            "image_classification"
          );

          var application_id_pt_1_13_1_fa_2_7_10 = utils.getApplicationId(
            "pytorch",
            "1.13.1",
            "fastai",
            "2.7.10",
            "image_classification"
          );

          var default_items = [
            {
              label: "Architecture",
              name: "arch",
              value: "resnet50",
              value_type: utils.job_value_type_multi,
              options: ["resnet18", "resnet34", "resnet50", "resnet101"],
              default_value: "resnet50",
              required: true
            },
            {
              label: "Image size",
              name: "image_size",
              value: "224",
              value_type: utils.job_value_type_number,
              default_value: "224"
            },
            {
              label: "Image resize",
              name: "image_resize",
              value: "460",
              value_type: utils.job_value_type_number,
              default_value: "460"
            },
            {
              label: "Batch size",
              name: "batch_size",
              value: "32",
              value_type: utils.job_value_type_number,
              default_value: "32"
            },
            {
              label: "Number of epochs",
              name: "nb_epochs",
              value: "50",
              value_type: utils.job_value_type_number
            },
            {
              label: "Mix up (%)",
              name: "mixup",
              value: "0",
              value_type: utils.job_value_type_number,
              default_value: "0"
            },
            {
              label: "Seed",
              name: "seed",
              value: "12",
              value_type: utils.job_value_type_number,
              default_value: "12"
            }
          ];

          var imageClassificationOptions = [
            {
              application_id: application_id_pt_2_3_0_fa_2_7_15,
              name: "fast.ai v2.7.15 - PyTorch 2.3.0",
              items: default_items
            },
            {
              application_id: application_id_pt_2_2_2_fa_2_7_14,
              name: "fast.ai v2.7.14 - PyTorch 2.2.2",
              items: default_items
            },
            {
              application_id: application_id_pt_2_2_0_fa_2_7_14,
              name: "fast.ai v2.7.14 - PyTorch 2.2.0",
              items: default_items
            },

            {
              application_id: application_id_pt_2_1_2_fa_2_7_13,
              name: "fast.ai v2.7.13 - PyTorch 2.1.2",
              items: default_items
            },
            {
              application_id: application_id_pt_2_0_1_fa_2_7_12,
              name: "fast.ai v2.7.12 - PyTorch 2.0.1",
              items: default_items
            },
            {
              application_id: application_id_pt_1_13_1_fa_2_7_10,
              name: "fast.ai v2.7.10 - PyTorch 1.13.1",
              items: default_items
            }
          ];

          this.options = imageClassificationOptions;
          this.selected = this.options[1];
        } else if (
          this.dataset.content_type == utils.dataset_content_type_tabular
        ) {
          var application_id_pt_1_13_1_fa_2_7_11_struct = utils.getApplicationId(
            "pytorch",
            "1.13.1",
            "fastai",
            "2.7.11",
            "structured"
          );

          var application_id_pt_2_1_2_fa_2_7_13_struct = utils.getApplicationId(
            "pytorch",
            "2.1.2",
            "fastai",
            "2.7.13",
            "structured"
          );

          var application_id_pt_2_2_0_fa_2_7_14_struct = utils.getApplicationId(
            "pytorch",
            "2.2.0",
            "fastai",
            "2.7.14",
            "structured"
          );

          var application_id_xgboost_1_7_4 = utils.getApplicationId(
            "",
            "",
            "xgboost",
            "1.7.4",
            "structured"
          );

          var application_id_xgboost_1_7_5 = utils.getApplicationId(
            "",
            "",
            "xgboost",
            "1.7.5",
            "structured"
          );

          var application_id_xgboost_1_7_6 = utils.getApplicationId(
            "",
            "",
            "xgboost",
            "1.7.6",
            "structured"
          );

          var application_id_xgboost_2_0_0 = utils.getApplicationId(
            "",
            "",
            "xgboost",
            "2.0.0",
            "structured"
          );

          var application_id_xgboost_2_0_1 = utils.getApplicationId(
            "",
            "",
            "xgboost",
            "2.0.1",
            "structured"
          );

          var application_id_xgboost_2_0_2 = utils.getApplicationId(
            "",
            "",
            "xgboost",
            "2.0.2",
            "structured"
          );

          var application_id_xgboost_2_1_0 = utils.getApplicationId(
            "",
            "",
            "xgboost",
            "2.1.0",
            "structured"
          );

          // var application_id_xgboost_1_7_3 = "1.7.3";

          // var application_id_xgboost_1_6_2 = "1.6.2";

          let exclude_columns_item = {
            label: "Exclude columns",
            name: "exclude_columns",
            value: "",
            value_type: utils.job_value_type_multi,
            multiple: true,
            options: this.getColumnNames(),
            default_value: []
          };

          let add_info_item = {
            label: "Add info",
            name: "add_info",
            value: true,
            value_type: utils.job_value_type_boolean,
            default_value: true
          };

          var xgboost_structured_items = [
            {
              label: "Booster",
              name: "booster",
              value: "gbtree",
              value_type: utils.job_value_type_multi,
              options: ["gbtree", "dart", "gblinear"],
              default_value: "gbtree",
              required: true
            },
            {
              label: "Nb Estimators",
              name: "nb_estimators",
              value: "20",
              value_type: utils.job_value_type_number,
              default_value: "20",
              hidden: false
            },
            {
              label: "Max depth",
              name: "max_depth",
              value: "6",
              value_type: utils.job_value_type_number,
              default_value: "6",
              hidden: false
            },
            {
              label: "Learning rate (eta)",
              name: "learning_rate",
              value: "0.3",
              value_type: utils.job_value_type_number,
              default_value: "0.3",
              step: 0.1
            },
            {
              label: "Subsample (0 - 1)",
              name: "subsample",
              value: "0.5",
              value_type: utils.job_value_type_number,
              default_value: "0.5",
              step: 0.1
            },
            {
              label: "Minimal feature importance (%)",
              name: "min_feat_importance",
              value: "0",
              value_type: utils.job_value_type_number,
              default_value: "0"
            }
          ];

          xgboost_structured_items.push(exclude_columns_item);
          xgboost_structured_items.push(add_info_item);

          var fastai_structured_items = [
            {
              label: "Architecture",
              name: "arch",
              value: "[200 - 100]",
              value_type: utils.job_value_type_text,
              default_value: "[200 - 100]",
              hidden: true
            },
            {
              label: "Batch size",
              name: "batch_size",
              value: "32",
              value_type: utils.job_value_type_number,
              default_value: "32"
            },
            {
              label: "Number of epochs",
              name: "nb_epochs",
              value: "50",
              value_type: utils.job_value_type_number,
              default_value: "50"
            },
            {
              label: "Minimal feature importance (%)",
              name: "min_feat_importance",
              value: "0",
              value_type: utils.job_value_type_number,
              default_value: "0"
            }
          ];

          fastai_structured_items.push(exclude_columns_item);
          fastai_structured_items.push(add_info_item);

          var application_id_catboost_1_1_1 = utils.getApplicationId(
            "",
            "",
            "catboost",
            "1.1.1",
            "structured"
          );

          var application_id_catboost_1_2 = utils.getApplicationId(
            "",
            "",
            "catboost",
            "1.2",
            "structured"
          );

          var application_id_catboost_1_2_1 = utils.getApplicationId(
            "",
            "",
            "catboost",
            "1.2.1",
            "structured"
          );

          var application_id_catboost_1_2_2 = utils.getApplicationId(
            "",
            "",
            "catboost",
            "1.2.2",
            "structured"
          );

          var application_id_catboost_1_2_3 = utils.getApplicationId(
            "",
            "",
            "catboost",
            "1.2.3",
            "structured"
          );

          var application_id_catboost_1_2_5 = utils.getApplicationId(
            "",
            "",
            "catboost",
            "1.2.5",
            "structured"
          );

          var application_id_catboost_1_2_7 = utils.getApplicationId(
            "",
            "",
            "catboost",
            "1.2.7",
            "structured"
          );

          var catboost_structured_items = [
            {
              label: "Nb Estimators",
              name: "nb_estimators",
              value: "3",
              value_type: utils.job_value_type_number,
              default_value: "3",
              hidden: false
            },
            // {
            //   label: "Depth",
            //   name: "depth",
            //   value: "6",
            //   value_type: utils.job_value_type_number,
            //   default_value: "6",
            //   hidden: false
            // },
            {
              label: "Learning rate",
              name: "learning_rate",
              value: "1",
              value_type: utils.job_value_type_number,
              default_value: "1",
              step: 0.1
            },
            {
              label: "Minimal feature importance (%)",
              name: "min_feat_importance",
              value: "0",
              value_type: utils.job_value_type_number,
              default_value: "0"
            }
          ];

          catboost_structured_items.push(exclude_columns_item);
          catboost_structured_items.push(add_info_item);

          var application_id_lightgbm_3_3_5 = utils.getApplicationId(
            "",
            "",
            "lightgbm",
            "3.3.5",
            "structured"
          );

          var application_id_lightgbm_4_0_0 = utils.getApplicationId(
            "",
            "",
            "lightgbm",
            "4.0.0",
            "structured"
          );

          var application_id_lightgbm_4_1_0 = utils.getApplicationId(
            "",
            "",
            "lightgbm",
            "4.1.0",
            "structured"
          );

          var application_id_lightgbm_4_2_0 = utils.getApplicationId(
            "",
            "",
            "lightgbm",
            "4.2.0",
            "structured"
          );

          var application_id_lightgbm_4_3_0 = utils.getApplicationId(
            "",
            "",
            "lightgbm",
            "4.3.0",
            "structured"
          );

          var application_id_lightgbm_4_4_0 = utils.getApplicationId(
            "",
            "",
            "lightgbm",
            "4.4.0",
            "structured"
          );

          var application_id_lightgbm_4_5_0 = utils.getApplicationId(
            "",
            "",
            "lightgbm",
            "4.5.0",
            "structured"
          );

          var lightgbm_structured_items = [
            {
              label: "Boosting Type",
              name: "boosting_type",
              value: "gbdt",
              value_type: utils.job_value_type_multi,
              options: ["gbdt", "dart", "rf"],
              default_value: "gbtree",
              required: true
            },
            {
              label: "Nb Estimators",
              name: "nb_estimators",
              value: "100",
              value_type: utils.job_value_type_number,
              default_value: "100",
              hidden: false
            },
            {
              label: "Max depth",
              name: "max_depth",
              value: "-1",
              value_type: utils.job_value_type_number,
              default_value: "-1",
              hidden: false
            },
            {
              label: "Learning rate",
              name: "learning_rate",
              value: "0.1",
              value_type: utils.job_value_type_number,
              default_value: "0.1",
              step: 0.1
            },
            {
              label: "Max number of leaves",
              name: "num_leaves",
              value: "31",
              value_type: utils.job_value_type_number,
              default_value: "31"
            },
            {
              label: "Minimal feature importance (%)",
              name: "min_feat_importance",
              value: "0",
              value_type: utils.job_value_type_number,
              default_value: "0"
            }
          ];

          lightgbm_structured_items.push(exclude_columns_item);
          lightgbm_structured_items.push(add_info_item);

          var structuredOptions = [
            {
              application_id: application_id_xgboost_2_1_0,
              name: "XGBoost v2.1.0",
              items: xgboost_structured_items
            },
            {
              application_id: application_id_xgboost_2_0_2,
              name: "XGBoost v2.0.2",
              items: xgboost_structured_items
            },
            {
              application_id: application_id_xgboost_2_0_1,
              name: "XGBoost v2.0.1",
              items: xgboost_structured_items
            },
            {
              application_id: application_id_xgboost_2_0_0,
              name: "XGBoost v2.0.0",
              items: xgboost_structured_items
            },
            {
              application_id: application_id_xgboost_1_7_6,
              name: "XGBoost v1.7.6",
              items: xgboost_structured_items
            },
            {
              application_id: application_id_xgboost_1_7_5,
              name: "XGBoost v1.7.5",
              items: xgboost_structured_items
            },

            {
              application_id: application_id_xgboost_1_7_4,
              name: "XGBoost v1.7.4",
              items: xgboost_structured_items
            },
            {
              application_id: application_id_catboost_1_2_7,
              name: "CatBoost v1.2.7",
              items: catboost_structured_items
            },
            {
              application_id: application_id_catboost_1_2_5,
              name: "CatBoost v1.2.5",
              items: catboost_structured_items
            },
            {
              application_id: application_id_catboost_1_2_3,
              name: "CatBoost v1.2.3",
              items: catboost_structured_items
            },
            {
              application_id: application_id_catboost_1_2_2,
              name: "CatBoost v1.2.2",
              items: catboost_structured_items
            },
            {
              application_id: application_id_catboost_1_2_1,
              name: "CatBoost v1.2.1",
              items: catboost_structured_items
            },
            {
              application_id: application_id_catboost_1_2,
              name: "CatBoost v1.2",
              items: catboost_structured_items
            },
            {
              application_id: application_id_catboost_1_1_1,
              name: "CatBoost v1.1.1",
              items: catboost_structured_items
            },
            {
              application_id: application_id_lightgbm_4_5_0,
              name: "LightGBM v4.5.0",
              items: lightgbm_structured_items
            },
            {
              application_id: application_id_lightgbm_4_4_0,
              name: "LightGBM v4.4.0",
              items: lightgbm_structured_items
            },
            {
              application_id: application_id_lightgbm_4_3_0,
              name: "LightGBM v4.3.0",
              items: lightgbm_structured_items
            },
            {
              application_id: application_id_lightgbm_4_2_0,
              name: "LightGBM v4.2.0",
              items: lightgbm_structured_items
            },
            {
              application_id: application_id_lightgbm_4_1_0,
              name: "LightGBM v4.1.0",
              items: lightgbm_structured_items
            },
            {
              application_id: application_id_lightgbm_4_0_0,
              name: "LightGBM v4.0.0",
              items: lightgbm_structured_items
            },

            {
              application_id: application_id_lightgbm_3_3_5,
              name: "LightGBM v3.3.5",
              items: lightgbm_structured_items
            },

            {
              application_id: application_id_pt_2_2_0_fa_2_7_14_struct,
              name: "fast.ai v2.7.14 - PyTorch 2.2.0",
              items: fastai_structured_items
            },
            {
              application_id: application_id_pt_2_1_2_fa_2_7_13_struct,
              name: "fast.ai v2.7.13 - PyTorch 2.1.2",
              items: fastai_structured_items
            },
            {
              application_id: application_id_pt_1_13_1_fa_2_7_11_struct,
              name: "fast.ai v2.7.11 - PyTorch 1.13.1",
              items: fastai_structured_items
            }
          ];

          this.options = structuredOptions;
          this.selected = this.options[1];
        } else if (this.dataset.content_type == "ner") {
          // -- Spacy config items --
          var spacy_ner_items = [
            {
              label: "Language",
              name: "language",
              value: "en",
              value_type: utils.job_value_type_text,
              default_value: "en",
              hidden: true
            },
            {
              label: "Optimize for",
              name: "optimize",
              value: "accuracy",
              value_type: utils.job_value_type_multi,
              options: ["accuracy", "efficiency"],
              default_value: "accuracy",
              required: true
            }
          ];

          // -- v3.7.4 --
          var application_id_v3_7_4 = utils.getApplicationId(
            "spacy",
            "3.7.4",
            "",
            "",
            "ner"
          );

          var v3_7_4 = {
            application_id: application_id_v3_7_4,
            name: "Spacy NER (v3.7.4)",
            items: spacy_ner_items
          };

          // -- End v3.7.3 config --

          // -- v3.7.3 --
          var application_id_v3_7_3 = utils.getApplicationId(
            "spacy",
            "3.7.3",
            "",
            "",
            "ner"
          );

          var v3_7_3 = {
            application_id: application_id_v3_7_3,
            name: "Spacy NER (v3.7.3)",
            items: spacy_ner_items
          };

          // -- End v3.7.3 config --

          // -- v3.7.2 --
          var application_id_v3_7_2 = utils.getApplicationId(
            "spacy",
            "3.7.2",
            "",
            "",
            "ner"
          );

          var v3_7_2 = {
            application_id: application_id_v3_7_2,
            name: "Spacy NER (v3.7.2)",
            items: spacy_ner_items
          };

          // -- End v3.7.2 config --

          // -- v3.5.0 --
          var application_id_v3_5_0 = utils.getApplicationId(
            "spacy",
            "3.5.0",
            "",
            "",
            "ner"
          );

          var v3_5_0 = {
            application_id: application_id_v3_5_0,
            name: "Spacy NER (v3.5.0)",
            items: spacy_ner_items
          };

          // -- End v3.5.0 config --
          var nerOptions = [v3_7_4, v3_7_3, v3_7_2, v3_5_0];

          this.options = nerOptions;
          this.selected = this.options[2];
        } else if (
          this.dataset.content_type == utils.dataset_content_type_documents
        ) {
          var search_items = [
            {
              label: "Extract synonyms",
              name: "extract_synonyms",
              value: false,
              value_type: utils.job_value_type_boolean,
              default_value: false,
              hidden: false
            }
          ];

          var application_id_v0_2_7_post1 = utils.getApplicationId(
            "bm25s",
            "0.2.7.post1",
            "",
            "",
            "search"
          );

          var v0_2_7_post1 = {
            application_id: application_id_v0_2_7_post1,
            name: "BM25s (v0.2.7.post1)",
            items: search_items
          };

          var application_id_v0_2_8 = utils.getApplicationId(
            "bm25s",
            "0.2.8",
            "",
            "",
            "search"
          );

          var v0_2_8 = {
            application_id: application_id_v0_2_8,
            name: "BM25s (v0.2.8)",
            items: search_items
          };

          var application_id_v0_2_9 = utils.getApplicationId(
            "bm25s",
            "0.2.9",
            "",
            "",
            "search"
          );

          var v0_2_9 = {
            application_id: application_id_v0_2_9,
            name: "BM25s (v0.2.9)",
            items: search_items
          };

          var application_id_v0_2_10 = utils.getApplicationId(
            "bm25s",
            "0.2.10",
            "",
            "",
            "search"
          );

          var v0_2_10 = {
            application_id: application_id_v0_2_10,
            name: "BM25s (v0.2.10)",
            items: search_items
          };

          var searchOptions = [v0_2_7_post1, v0_2_8, v0_2_9, v0_2_10];

          this.options = searchOptions;
          this.selected = this.options[3];
        } else {
          this.train_not_supported = true;
        }

        this.getModels(() => {
          this.$bvModal.show("train-dataset");
        });
      });
    },
    changePage: function(forward, cb, index) {
      if (forward && !this.showNextPage) {
        return;
      }

      if (!forward && !this.showPreviousPage) {
        return;
      }

      // Reset PDF page count
      this.pdfCurrentPage = 1;

      if (forward) {
        this.pageCount += 1;
      } else {
        this.pageCount -= 1;
      }

      if (this.pageCount < 0) {
        this.pageCount = 0;
      }

      this.pushUrl(this.$route.path);

      this.getItems(cb, index);
    },
    setNextEditImage: function(index) {
      if (index != undefined) {
        this.selectedEditItemIndex = index;
      }

      this.editItem(
        this.items[this.selectedEditItemIndex],
        this.selectedEditItemIndex
      );
    },
    keyNavigate: function(forward) {
      if (this.preventAnnotation) {
        return;
      }

      if (this.selectedEditItemIndex >= 0) {
        this.changeImage(forward);
      } else {
        this.changePage(forward);
      }
    },
    changeImage: function(forward) {
      if (forward && !this.showNextImage()) {
        return;
      }

      if (!forward && !this.showPreviousImage()) {
        return;
      }

      this.rect = {};
      let samePage = true;

      if (forward) {
        if (this.selectedEditItemIndex + 1 < this.pageSize) {
          this.selectedEditItemIndex += 1;
        } else {
          samePage = false;
        }
      } else {
        if (this.selectedEditItemIndex > 0) {
          this.selectedEditItemIndex -= 1;
        } else {
          samePage = false;
        }
      }

      if (samePage) {
        this.setNextEditImage();
      } else {
        var setEditIndex = 0;

        if (!forward) {
          setEditIndex = this.pageSize - 1;
        }

        this.changePage(forward, this.setNextEditImage, setEditIndex);
      }

      this.pdfCurrentPage = 1;
    },
    clearSelectEditItem: function() {
      this.selectedEditItem = {};
      this.selectedEditItemIndex = -1;
      this.rect = {};
      let new_url = `/datasets/${this.dataset.id}/versions/${this.selectedVersion.id}/splits/${this.selectedSplit.id}`;

      if (this.selectedLabel && this.selectedLabel.id) {
        new_url = `${new_url}/labels/${this.selectedLabel.id}`;
      }
      //this.$router.push(new_url);
      this.pushUrl(new_url);

      // TODO: remove from url
    },
    selectSplit: function(split) {
      if (split.id == this.selectedSplit.id) {
        return;
      }
      this.clearSelectEditItem();
      this.selectedSplit = split;
      this.pageCount = 0;
      let new_url = `/datasets/${this.dataset.id}/versions/${this.selectedVersion.id}/splits/${this.selectedSplit.id}`;

      if (this.selectedLabel && this.selectedLabel.id) {
        new_url = `${new_url}/labels/${this.selectedLabel.id}`;
      }
      //this.$router.push(new_url);
      this.pushUrl(new_url);

      this.getItems();
    },
    pushUrl: function(url, query) {
      if (!query) {
        query = {};
      }

      query["pageSize"] = this.pageSize;
      query["pageCount"] = this.pageCount;

      this.$router.push({ path: url, query: query });
    },
    selectVersion: function(version) {
      this.clearSelectEditItem();
      this.selectedLabel = {};
      this.selectedUploadLabel = {};

      //this.$router.replace(
      // `/datasets/${this.dataset.id}/versions/${version.id}`
      // );
      let new_url = `/datasets/${this.dataset.id}/versions/${version.id}`;
      this.pushUrl(new_url);

      this.initVersion(version);
    },
    initVersion: function(version) {
      this.selectedVersion = version;
      this.splits = this.selectedVersion.splits;

      this.initLabels(this.selectedVersion.labels);

      // -- Route Query --
      let url_q = this.$route.query;

      this.pageCount = url_q.pageCount ? url_q.pageCount : 0;
      this.pageSize = url_q.pageSize ? url_q.pageSize : this.pageSize;

      // -- Set up splits --

      if (this.splits == undefined) {
        this.splits = [];
      }

      let split_id = this.$route.params.split_id;

      let split = undefined;

      if (split_id) {
        split = this.splits.find(s => {
          return s.id == split_id;
        });
      }

      if (split) {
        this.selectedSplit = split;
      } else {
        this.selectedDefaultSplit = this.splits.find(s => {
          return s.id == this.selectedVersion.default_split;
        });

        if (this.selectedDefaultSplit) {
          this.selectedSplit = this.selectedDefaultSplit;
        } else if (this.splits.length > 0) {
          this.selectedSplit = this.splits[0];
        } else {
          this.selectedSplit = {};
        }
      }

      // -- Set up Label --

      let label_id = this.$route.params.label_id;

      let label = undefined;

      if (label_id) {
        label = this.labels.find(l => {
          return l.id == label_id;
        });
      }

      if (label) {
        this.selectedLabel = label;
      }

      let itemsCb = () => {
        // -- Set up Selected Item --
        let item_id = this.$route.params.item_id;

        let item = undefined;

        if (item_id) {
          item = this.filteredItems.find(i => {
            return i.id == item_id;
          });
        }

        if (item) {
          this.selectedEditItem = item;
          let index = this.utils.findIndexById(this.filteredItems, item.id);
          this.selectedEditItemIndex = index;
        }
      };

      this.items = [];

      if (this.dataset.content_type == utils.dataset_content_type_tabular) {
        let column_names = utils.csv_column_names_key;

        if (
          this.selectedVersion.config &&
          this.selectedVersion.config.length > 0
        ) {
          this.versionConfig = JSON.parse(this.selectedVersion.config);
          if (column_names in this.versionConfig) {
            let csv_separator = this.versionConfig[utils.csv_separator_key];
            var columns = this.versionConfig[column_names].split(csv_separator);

            var fields = [];
            columns.forEach(c => {
              var new_column = {
                key: c,
                sortable: true
              };

              fields.push(new_column);
            });

            //let edit_column = {
            //  key: "Edit",
            //  sortable: false
            // }

            //fields.push(edit_column)

            let delete_column = {
              key: "Delete",
              sortable: false
            };

            fields.push(delete_column);

            this.versionFields = fields;
          }
        }
      }

      this.getItems(itemsCb);
    },
    customModelVersionLabel: function(version) {
      if (!version.name) {
        return "";
      }

      let version_label = `${version.version_number}: ${version.name}`;

      if (version.metrics.length > 0) {
        version_label += ` (${version.metrics[0].name}: ${version.metrics[0].value})`;
      }
      return version_label;
    },
    initLabels: function(labels) {
      labels = this.sortLabels(labels);

      labels.forEach(a => {
        this.labelColors[a.id] = a.color;
      });

      this.labels = labels || [];
      this.filteredLabels = labels || [];
      this.filteredAnnotationLabels = JSON.parse(JSON.stringify(labels)) || [];

      this.newLabel = "";
    },
    clickLabelSort: function() {
      this.sortLabelsIndex =
        (this.sortLabelsIndex + 1) % this.sortLabelsOptions.length;
      this.filteredLabels = this.sortLabels(this.filteredLabels);
      this.filteredAnnotationLabels = this.sortLabels(
        this.filteredAnnotationLabels
      );
    },
    sortOnLabelStats(labels, prop_name) {
      labels = labels.sort((l1, l2) => {
        // Labels with no items or annotations are not present in this.labelStats
        if (!this.labelStats[l1.id] || !this.labelStats[l1.id][prop_name]) {
          return -1;
        }

        // Labels with no items or annotations are not present in this.labelStats
        if (!this.labelStats[l2.id] || !this.labelStats[l2.id][prop_name]) {
          return 1;
        }

        if (
          this.labelStats[l1.id][prop_name] < this.labelStats[l2.id][prop_name]
        ) {
          return -1;
        }
        if (
          this.labelStats[l1.id][prop_name] < this.labelStats[l2.id][prop_name]
        ) {
          return 1;
        }
        return 0;
      });

      return labels;
    },
    sortLabels: function(labels) {
      var sort_option = this.sortLabelsOptions[this.sortLabelsIndex];

      if (sort_option == this.utils.sort_option_index) {
        labels = labels.sort((l1, l2) => {
          if (l1.index < l2.index) {
            return -1;
          }
          if (l1.index > l2.index) {
            return 1;
          }
          return 0;
        });
      } else if (sort_option == this.utils.sort_option_count_high_low) {
        labels = this.sortOnLabelStats(labels, "item_count");
        labels = labels.reverse();
      } else if (sort_option == this.utils.sort_option_count_low_high) {
        labels = this.sortOnLabelStats(labels, "item_count");
      } else if (sort_option == this.utils.sort_option_annotation_high_low) {
        labels = this.sortOnLabelStats(labels, "annotation_count");
        labels = labels.reverse();
      } else if (sort_option == this.utils.sort_option_annotation_low_high) {
        labels = this.sortOnLabelStats(labels, "item_count");
      } else {
        labels = labels.sort((l1, l2) => {
          if (l1.name.toLowerCase() < l2.name.toLowerCase()) {
            return -1;
          }
          if (l1.name.toLowerCase() < l2.name.toLowerCase()) {
            return 1;
          }
          return 0;
        });

        if (sort_option == this.utils.sort_option_reversed_alphabetically) {
          labels = labels.reverse();
        }
      }

      if (labels == undefined) {
        labels = [];
      }

      return labels;
    },
    getSortLabel: function() {
      var sort_option = this.sortLabelsOptions[this.sortLabelsIndex];

      var sort_type = "";

      switch (sort_option) {
        case this.utils.sort_option_alphabetically:
          sort_type = "alphabetically";
          break;
        case this.utils.sort_option_reversed_alphabetically:
          sort_type = "reversed alphabetically";
          break;
        case this.utils.sort_option_index:
          sort_type = "on index";
          break;
        case this.utils.sort_option_count_high_low:
          sort_type = "on item count (high to low)";
          break;
        case this.utils.sort_option_count_low_high:
          sort_type = "on item count (low to high)";
          break;
        case this.utils.sort_option_annotation_high_low:
          sort_type = "on annotation count (high to low):";
          break;
        case this.utils.sort_option_annotation_low_high:
          sort_type = "on annotation count (low to high)";
          break;
      }

      return `Labels sorted ${sort_type}`;
    },
    checkCloseEnough: function(p1, p2) {
      return Math.abs(p1 - p2) < this.closeEnough;
    },
    handleMouseDown(e) {
      // set a flag indicating the drag has begun
      this.isDown = true;

      if (this.dataset.content_type == "ner") {
        this.rect = {};
        this.draw(false);
        return;
      }

      e.preventDefault();
      e.stopPropagation();

      this.ctx.strokeStyle = "11ffdd";
      this.ctx.lineWidth = 1;

      var canvasOffset = this.canvas.getBoundingClientRect();

      this.offsetX = canvasOffset.left;
      this.offsetY = canvasOffset.top;

      // save the starting x/y of the rectangle
      this.startX = parseInt(e.clientX - this.offsetX);
      this.startY = parseInt(e.clientY - this.offsetY);

      var candidates = [];

      var click = {
        x: this.startX,
        y: this.startY
      };

      if (this.selectedEditItem && this.selectedEditItem.annotations) {
        this.selectedEditItem.annotations.forEach(a => {
          if (a.coordinates) {
            var coords = this.convertToActual(a.coordinates);
            var c = this.dragCandidate(a, coords, click);

            if (c) {
              candidates.push(c);
            }
          }
        });
      }

      var rectCandate = this.dragCandidate(null, this.rect, click);

      if (rectCandate) {
        candidates.push(rectCandate);
      }

      if (candidates.length > 0) {
        candidates.sort((a, b) => {
          return a.distance - b.distance;
        });

        var selection = candidates[0];

        this.annotationToUpdate = selection.annotation;
        this.dragTL = selection.dragTL;
        this.dragTR = selection.dragTR;
        this.dragBR = selection.dragBR;
        this.dragBL = selection.dragBL;

        if (this.annotationToUpdate) {
          this.rect = this.convertToActual(this.annotationToUpdate.coordinates);
        }
      } else {
        this.rect = {};
        this.rect.x = this.startX;
        this.rect.y = this.startY;
      }
    },
    dragCandidate: function(annotation, shape, click) {
      var candidate;

      if (
        this.checkCloseEnough(click.x, shape.x) &&
        this.checkCloseEnough(click.y, shape.y)
      ) {
        candidate = {
          annotation: annotation,
          dragTL: true,
          distance: utils.distance(click.x, click.y, shape.x, shape.y)
        };
      } else if (
        this.checkCloseEnough(click.x, shape.x + shape.w) &&
        this.checkCloseEnough(click.y, shape.y)
      ) {
        candidate = {
          annotation: annotation,
          dragTR: true,
          distance: utils.distance(click.x, click.y, shape.x + shape.w, shape.y)
        };
        //this.dragTR = true;
      } else if (
        this.checkCloseEnough(click.x, shape.x + shape.w) &&
        this.checkCloseEnough(click.y, shape.y + shape.h)
      ) {
        candidate = {
          annotation: annotation,
          dragBR: true,
          distance: utils.distance(
            click.x,
            click.y,
            shape.x + shape.w,
            shape.y + shape.h
          )
        };
        //this.dragBR = true;
      } else if (
        this.checkCloseEnough(click.x, shape.x) &&
        this.checkCloseEnough(click.y, shape.y + shape.h)
      ) {
        candidate = {
          annotation: annotation,
          dragBL: true,
          distance: utils.distance(
            click.x,
            click.y,
            shape.x,
            shape.y + shape.h
          ),
          isAnnotation: true
        };
        //this.dragBL = true;
      }

      return candidate;
    },
    handleMouseMove(e) {
      e.preventDefault();
      e.stopPropagation();

      // if we're not dragging, just return
      if (this.isDown) {
        // get the current mouse position
        this.mouseX = parseInt(e.clientX - this.offsetX);
        this.mouseY = parseInt(e.clientY - this.offsetY);

        if (this.dragTL) {
          this.rect.w += this.rect.x - this.mouseX;
          this.rect.h += this.rect.y - this.mouseY;
          this.rect.x = this.mouseX;
          this.rect.y = this.mouseY;
        } else if (this.dragTR) {
          this.rect.w = this.mouseX - this.rect.x;
          this.rect.h += this.rect.y - this.mouseY;
          this.rect.y = this.mouseY;
        } else if (this.dragBL) {
          this.rect.w += this.rect.x - this.mouseX;
          this.rect.h = this.mouseY - this.rect.y;
          this.rect.x = this.mouseX;
        } else if (this.dragBR) {
          this.rect.w = this.mouseX - this.rect.x;
          this.rect.h = this.mouseY - this.rect.y;
        } else {
          this.rect.w = this.mouseX - this.rect.x;
          this.rect.h = this.mouseY - this.rect.y;
        }

        if (this.annotationToUpdate) {
          this.annotationToUpdate.coordinates = this.convertToYolo(this.rect);
        }

        this.draw(true);
      } else {
        if (
          this.dataset.content_type == utils.dataset_content_type_images &&
          this.dataset.multi_label
        ) {
          this.annotationsEditable = [];
          document.body.style.cursor = "default";

          this.mouseX = parseInt(e.clientX - this.offsetX);
          this.mouseY = parseInt(e.clientY - this.offsetY);

          if (this.selectedEditItem && this.selectedEditItem.annotations) {
            this.selectedEditItem.annotations.forEach(a => {
              if (a.coordinates) {
                let coords = this.convertToActual(a.coordinates);

                if (
                  coords.x - this.annotationsEditableTolerance <= this.mouseX &&
                  this.mouseX <=
                    coords.x + coords.w + this.annotationsEditableTolerance &&
                  coords.y - this.annotationsEditableTolerance <= this.mouseY &&
                  this.mouseY <=
                    coords.y + coords.h + this.annotationsEditableTolerance
                ) {
                  this.annotationsEditable.push(a);

                  let distance1 = Math.hypot(
                    this.mouseX - coords.x,
                    this.mouseY - coords.y
                  );
                  let distance2 = Math.hypot(
                    this.mouseX - (coords.x + coords.w),
                    this.mouseY - coords.y
                  );
                  let distance3 = Math.hypot(
                    this.mouseX - coords.x,
                    this.mouseY - (coords.y + coords.h)
                  );
                  let distance4 = Math.hypot(
                    this.mouseX - (coords.x + coords.w),
                    this.mouseY - (coords.y + coords.h)
                  );

                  if (
                    distance1 <= this.annotationsEditableTolerance ||
                    distance2 <= this.annotationsEditableTolerance ||
                    distance3 <= this.annotationsEditableTolerance ||
                    distance4 <= this.annotationsEditableTolerance
                  ) {
                    document.body.style.cursor = "move";
                  }
                }
              }
            });
          }
        }

        this.draw(true, false);
      }
    },
    handleMouseUp(e) {
      this.isDown = false;

      if (this.dataset.content_type == "ner") {
        e.preventDefault();
        e.stopPropagation();

        var offset = this.getSelectionOffsetRelativeTo(
          document.getElementById("selected-edit-item-text")
        );

        var selectedText = this.itemText.substring(
          offset[0],
          offset[0] + offset[1]
        );

        var selectedTextLength = selectedText.length;

        // Determine how many leading characters will need to be removed
        var trimmedStartText = selectedText.trimStart();
        var trimmedStartTextLength = trimmedStartText.length;

        var trimmedStartCount = selectedTextLength - trimmedStartTextLength;

        // Determine how many trailing characters will need to be removed
        var trimmedEndText = selectedText.trimEnd();
        var trimmedEndTextLength = trimmedEndText.length;

        var trimmedEndCount = selectedTextLength - trimmedEndTextLength;

        this.rect.startOffset = offset[0] + trimmedStartCount;
        this.rect.endOffset = offset[0] + offset[1] - trimmedEndCount;

        if (this.rect.startOffset == this.rect.endOffset) {
          this.draw(false, true);
        } else {
          this.draw(false);
        }
      } else {
        e.preventDefault();
        e.stopPropagation();

        if (this.rect.w < 0) {
          this.rect.x += this.rect.w;
          this.rect.w = Math.abs(this.rect.w);
        }

        if (this.rect.h < 0) {
          this.rect.y += this.rect.h;
          this.rect.h = Math.abs(this.rect.h);
        }

        if (this.annotationToUpdate) {
          this.annotationToUpdate.coordinates = this.convertToYolo(this.rect);
          this.rect = {};

          this.updateAnnotation(this.annotationToUpdate);
        }

        this.draw(false);

        this.dragTL = this.dragTR = this.dragBL = this.dragBR = false;

        //this.updateItem(this.selectedEditItem);
      }

      this.selectedEditAnnotation = {};
    },
    handleMouseOut(e) {
      e.preventDefault();
      e.stopPropagation();

      this.isDown = false;
    },
    drawCircle: function(ctx, center_x, center_y) {
      ctx.beginPath();
      ctx.arc(
        center_x,
        center_y,
        this.annotationsEditableTolerance,
        0,
        2 * Math.PI
      );
      ctx.fillStyle = "#a9a9a9";
      ctx.fill();
      ctx.lineWidth = 2;
      ctx.strokeStyle = "#949494";

      this.ctx.stroke();
    },
    draw: function(crosshair = false, clearNewRect = false) {
      if (this.dataset.content_type == "ner") {
        if (this.selectedEditItem && this.selectedEditItem.annotations) {
          if (clearNewRect) {
            this.rect = {};
          }

          var err = this.utils.highlight(
            "selected-edit-item-text",
            this.selectedEditItem.text,
            this.rect,
            this.selectedEditItem.annotations,
            this.labelColors,
            utils.default_selection_color,
            this.hoverAnnotation
          );

          if (err) {
            this.draw(false, true);
          }

          return;
        }
      }

      if (!this.ctx) {
        return;
      }

      this.ctx.strokeStyle = "black";
      this.ctx.setLineDash([]);
      this.ctx.globalAlpha = 1;
      this.ctx.lineWidth = 1;

      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      if (clearNewRect) {
        this.rect = {};
      }

      if (this.rect.x) {
        this.ctx.strokeStyle = "#0a8fcf";
        this.ctx.strokeRect(
          this.rect.x + 0.5,
          this.rect.y + 0.5,
          this.rect.w,
          this.rect.h
        );

        this.ctx.globalAlpha = 0.3;
        this.ctx.fillStyle = this.ctx.strokeStyle;

        this.ctx.fillRect(
          Math.round(this.rect.x) + 1,
          Math.round(this.rect.y) + 1,
          Math.round(this.rect.w) - 1,
          Math.round(this.rect.h) - 1
        );
      }

      if (this.selectedEditItem && this.selectedEditItem.annotations) {
        this.selectedEditItem.annotations.forEach(a => {
          if (a.coordinates) {
            this.ctx.setLineDash([]);
            this.ctx.globalAlpha = 1;
            var coordsToDraw = this.convertToActual(a.coordinates);

            this.ctx.strokeStyle = this.labelColors[a.label_id];

            this.ctx.lineWidth = 1;

            if (this.hoverAnnotation && this.hoverAnnotation.id == a.id) {
              this.ctx.setLineDash([4, 4]);
              this.ctx.lineWidth = 2;
            }

            this.ctx.strokeRect(
              Math.round(coordsToDraw.x) + 0.5,
              Math.round(coordsToDraw.y) + 0.5,
              coordsToDraw.w,
              coordsToDraw.h
            );

            let globalAlpha = this.globalAlpha;

            this.ctx.globalAlpha = 0.3;
            this.ctx.fillStyle = this.ctx.strokeStyle;

            this.ctx.fillRect(
              Math.round(coordsToDraw.x) + 1,
              Math.round(coordsToDraw.y) + 1,
              Math.round(coordsToDraw.w) - 1,
              Math.round(coordsToDraw.h) - 1
            );

            let annotationIsEditable = this.annotationsEditable.find(ae => {
              return ae.id == a.id;
            });
            if (annotationIsEditable) {
              this.drawCircle(this.ctx, coordsToDraw.x, coordsToDraw.y);
              this.drawCircle(
                this.ctx,
                coordsToDraw.x + coordsToDraw.w,
                coordsToDraw.y
              );
              this.drawCircle(
                this.ctx,
                coordsToDraw.x,
                coordsToDraw.y + coordsToDraw.h
              );
              this.drawCircle(
                this.ctx,
                coordsToDraw.x + coordsToDraw.w,
                coordsToDraw.y + coordsToDraw.h
              );
            }
            this.ctx.lineWidth = 1;
            this.ctx.globalAlpha = globalAlpha;
          }
        });

        if (crosshair) {
          this.ctx.strokeStyle = "#505050";

          this.ctx.globalAlpha = 0.5;

          this.ctx.beginPath();
          this.ctx.setLineDash([5, 5]);

          this.ctx.moveTo(this.mouseX + 0.5, 0);
          this.ctx.lineTo(this.mouseX + 0.5, this.canvasHeight);
          this.ctx.stroke();

          this.ctx.beginPath();

          this.ctx.moveTo(0, this.mouseY + 0.5);
          this.ctx.lineTo(this.canvasWidth, this.mouseY + 0.5);
          this.ctx.stroke();
        }
      }
    },
    convertToYolo: function(coord) {
      var dw = 1 / this.canvasWidth;
      var dh = 1 / this.canvasHeight;

      var x = coord.x + coord.w / 2;
      var y = coord.y + coord.h / 2;

      var w = coord.w;
      var h = coord.h;

      x = x * dw;
      w = w * dw;
      y = y * dh;
      h = h * dh;

      return `${x} ${y} ${w} ${h}`;
    },
    convertToActual: function(coord, round) {
      var split = coord.split(" ");

      var c0 = parseFloat(split[0]);
      var c1 = parseFloat(split[1]);
      var c2 = parseFloat(split[2]);
      var c3 = parseFloat(split[3]);

      var w = this.canvasWidth;
      var h = this.canvasHeight;

      var x2 = parseFloat((2 * w * c0 + w * c2) / 2);
      var x1 = parseFloat((2 * w * c0 - w * c2) / 2);

      var y2 = parseFloat((2 * h * c1 + h * c3) / 2);
      var y1 = parseFloat((2 * h * c1 - h * c3) / 2);

      if (round) {
        return {
          x: Math.round(x1),
          y: Math.round(y1),
          w: Math.round(x2 - x1),
          h: Math.round(y2 - y1)
        };
      }

      return {
        x: x1,
        y: y1,
        w: x2 - x1,
        h: y2 - y1
      };
    },
    LabelFilterPlaceholder: function() {
      return `Filter ${this.labels.length} labels`;
    },
    getApplications: function(cb) {
      this.$http.get(`${utils.applicationsApi}`).then(res => {
        this.applications = res.data;
        if (cb) {
          cb();
        }
      });
    },
    getModels: function(cb) {
      this.$http.get(`${utils.modelApi}`).then(res => {
        var user_id = localStorage.getItem("user_id");

        this.models = res.data.filter(m => {
          return m.user_id == user_id || m.shared_with_me;
        });

        if (cb) {
          cb();
        }
      });
    },
    hideTraining: function() {
      if (this.train_not_supported) {
        return true;
      }

      return false;
    },
    annotationSubtext: function(annotation) {
      if (this.dataset.content_type == utils.dataset_content_type_ner) {
        var split = annotation.coordinates.split(" ");
        return `start: ${split[0]} end: ${split[1]}`;
      } else if (
        this.dataset.content_type == utils.dataset_content_type_images &&
        this.dataset.multi_label
      ) {
        var real_coords = this.convertToActual(annotation.coordinates);
        return `x: ${Math.round(real_coords.x)} y: ${Math.round(
          real_coords.y
        )} w: ${Math.round(real_coords.w)} h: ${Math.round(real_coords.h)}`;
      }

      return annotation.coordinates;
    },
    annotationValues: function(annotation) {
      if (this.dataset.content_type == utils.dataset_content_type_ner) {
        var split = annotation.coordinates.split(" ");
        annotation.annotation_inputs = [
          {
            label: "Start",
            value: split[0]
          },
          {
            label: "End",
            value: split[1]
          }
        ];
      } else if (
        this.dataset.content_type == utils.dataset_content_type_images &&
        this.dataset.multi_label
      ) {
        var real_coords = this.convertToActual(annotation.coordinates, true);
        annotation.annotation_inputs = [
          {
            label: "X",
            value: real_coords.x
          },
          {
            label: "Y",
            value: real_coords.y
          },
          {
            label: "W",
            value: real_coords.w
          },
          {
            label: "H",
            value: real_coords.h
          }
        ];
      }
    },
    navigateToDatasets: function() {
      this.$router.push({
        name: "Datasets"
      });
    },
    updateLabelHeight() {
      var datasetFooterEl = utils.el("dataset-footer");

      if (!datasetFooterEl) {
        return;
      }

      var footerTop = datasetFooterEl.getBoundingClientRect().top;

      var filterAreaEl = utils.el("filter-area");

      if (!filterAreaEl) {
        return;
      }

      var filterBottom = filterAreaEl.getBoundingClientRect().bottom;

      this.labelHeight = footerTop - filterBottom - 20;
    },
    pdfHasNextPage: function() {
      return this.pdfCurrentPage < this.pdfNumPages;
    },
    pdfShowNextPage: function() {
      this.pdfCurrentPage += 1;
    },
    pdfHasPreviousPage: function() {
      return this.pdfCurrentPage > 1;
    },
    pdfShowPreviousPage: function() {
      this.pdfCurrentPage -= 1;
    }
  },
  mounted() {
    this.utils.getApplications(this.$http);

    var height_count = Math.round((window.innerHeight - 90) / 180) - 1;

    var width_count = Math.round((window.innerWidth - 350) / 170) - 1;

    this.pageSize = height_count * width_count;

    this.selectedSort = this.sortOptions[0];
    this.getDataset();

    this.getUserId();
    this.$ga.page("dataset");
    utils.showMenu(false, true, this);

    this.rect = { a: "" };

    this.updateLabelHeight();
  },
  updated() {
    this.updateLabelHeight();
  },
  created() {
    window.addEventListener("resize", this.updateLabelHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.updateLabelHeight);
  }
};
</script>

<style scoped lang="scss">
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  grid-gap: 1rem;
  max-width: 100%;
  margin: 0.5rem auto;
  padding: 0 0.2rem;
}

.gallery-panel {
  position: relative;
  height: 180px;
  width: 170px;
  overflow: hidden;
}

.gallery-panel img {
  width: 170px;
  height: 180px;
  object-fit: cover;
  border-radius: 0.15rem;
  display: block;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #1f333db3;
  /* Black see-through */
  color: white;
  width: 100%;
  transition: 0.3s ease;

  font-size: 13px;
  padding: 10px 0;
  text-align: center;
  height: 40%;
  display: block;
  border-radius: 0px;
}

/* When you mouse over the container, fade in the overlay title */
.gallery-panel:hover .overlay {
  opacity: 1;
}

.filename {
  color: #f1f1f1;
  overflow-wrap: anywhere;
  float: right;
  padding-right: 5px;
}

.item-delete {
  position: absolute;
  bottom: 3px;
  right: 10px;

  &:hover {
    font-size: 16px;
    transition: 0.2s ease;
  }
}

.item-annotation {
  position: absolute;
  top: 3px;
  left: 3px;
  color: white;
}

.item-filename {
  position: absolute;
  bottom: 3px;
  left: 3px;
  color: white;
  max-width: 80%;
}

.add-version-section,
.add-split,
.add-label-section {
  cursor: pointer;
}

.split-section,
.add-version-section {
  display: inherit;
  margin: 10px 0;
}

.add-label-section {
  display: flex;
  margin: 10px 0;
}

.add-split {
  margin-top: 6px;
  margin-left: 5px;
}

#label-container {
  position: absolute;
  display: inline-block;
  width: 20%;
  padding: 0 5px;
  overflow: auto;
  height: 80%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: fixed;
}

.label {
  font-size: 14px;
}

.lbl-btn {
  padding: 0px 4px;

  .fas,
  .far,
  .fa {
    font-size: 14px;
  }
}

.card-body {
  padding: 0;
}

.no-display {
  display: none;
}

.label-list {
  overflow-y: auto;
  margin-top: 10px;
  position: inherit;
  scrollbar-width: none;
}

.dataset-mgmt {
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 999;
  box-sizing: border-box;
  text-align: left;
  background-color: rgb(42, 42, 46);
  color: white;
  padding: 10px;
}

#dataset-footer {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 320px;
  margin-left: 10px;
  margin-right: 10px;
}

#item-detail-footer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  width: 320px;
  margin-left: 10px;
  margin-right: 10px;
}

.option-edit {
  display: flex;
  justify-content: space-between;
}

.dropdown {
  position: relative;
  width: 93%;
  max-width: 350px;
}

.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid transparent;
  background: #edf2f7;
  line-height: 1.5em;
  outline: none;
  border-radius: 8px;
}

.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}

.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  width: 100%;
  max-height: 500px;
  margin-top: 4px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #edf2f7;
}

.dropdown-item-flag {
  max-width: 24px;
  max-height: 18px;
  margin: auto 12px auto 0px;
}

.selectedLabel {
  background-color: rgba(70, 179, 229, 0.4);
}

.row {
  margin-left: 0px;
  margin-right: 0px;
  padding: 8px 0;
}

.annotation {
  padding: 8px;
}

.label-url {
  width: 78%;
}

.label-name {
  display: inline-flex;
  white-space: nowrap;
  width: 69%;
  padding-left: 5px;
}

.label-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
}

.label-count {
  float: right;
  font-size: 14px;
  padding: 1px 0;
  display: inline;
}

.annotation-image {
  margin: 0 auto;
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
}

.annotation-delete {
  float: right;
  margin-right: 20px;
}

#img-form {
  text-align: left;
}

#img-container {
  position: relative;
  display: inline-block;
  width: 75%;
  height: 80%;
}

.prev-img,
.next-img,
.close-image-detail {
  width: 44px;
  height: 44px;
  position: absolute;
  z-index: 10;
  cursor: pointer;
  background-color: rgba(240, 240, 240, 0.5);
  font-weight: bolder;
  text-align: center;
  padding-top: 10px;
}

.close-image-detail {
  top: 0;
  right: 0;
}

.prev-img {
  top: 450px;
}

.next-img {
  right: 0px;
  top: 450px;
}

.col-sm {
  padding-left: 5px;
  padding-right: 5px;
}

canvas {
  top: 0;
}

.upload {
  width: 80%;
}

.show-breaks {
  white-space: break-spaces;
}

.subtext {
  color: #ccc;
  margin-left: 5px;
  font-size: medium;
}

.dropdown-and-add {
  display: flex;
  width: 100%;

  .select {
    width: 100%;
  }
}

.add {
  margin: auto;
  display: inline-block;
  line-height: 1;
  padding: 0.25rem 0.25rem;
  cursor: pointer;

  .fas {
    padding: 8px;

    &:hover {
      background-color: #efefef;
      border-radius: 16px;
    }
  }
}

.upload-btn {
  width: 200px;
  margin-top: 20px;
}

.edit-annotation-label {
  width: 60px;
  display: inline-block;
  padding-left: 5px;
}

.edit-annotation-input {
  width: 50px;
  text-align: right;
}

.edit-annotation-btn {
  width: 75px;
}

#annotation-image-container {
  margin: 0 auto;
}

.pdfNext {
  position: absolute;
  right: 48%;
  top: 0%;
}

.pdfPrevious {
  position: absolute;
  left: 48%;
  top: 0%;
}
</style>
