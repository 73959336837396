<template>
  <div>
    <div
      class="drop-zone mx-auto"
      @click="selectSound"
      @dragover="dragover"
      @dragend="enddrag"
      @dragleave="enddrag"
      @drop="drop"
    >
      <span v-if="showDropZone" class="drop-zone__prompt"
        >Drop/Upload your sound file here or just record below...</span
      >
      <audio
        id="audio"
        controls
        :src="sound"
        class="drop-zone__thumb"
        v-if="!showDropZone"
      ></audio>

      <input
        id="sound-input"
        type="file"
        name="file"
        :accept="acceptExtensions"
        @change="showSelectedSound"
        class="drop-zone__input"
        ref="audioInput"
      />
    </div>
    <label id="upload-label">{{ uploadLbl }}</label>
    <div>
      <button class="audio-buttons" @click="clickRecordBtn">
        <img class="audio-image" :src="getRecordingIcon()" />
      </button>
      <button
        class="audio-buttons"
        :class="{ 'opacity-2': !(isRecording || isPaused) }"
        @click="stopRecording"
      >
        <img class="audio-image" :src="utils.getIcon('stop-recording.svg')" />
      </button>
    </div>
    <div>
      <button
        id="next-btn"
        class="second-action-button action-btn"
        v-if="!btn_disabled"
        @click="selectSound"
      >
        {{ prediction_label }}
      </button>
      <span v-if="force_disabled"><i class="fas fa-spinner fa-pulse"></i></span>
    </div>
    <form class="w-25 mx-auto" v-show="show_form">
      <div class="">
        <label for="prediction">Transcription:</label>
        <span id="prediction">
          {{ prediction }}
        </span>
      </div>
    </form>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";

export default {
  name: "SpeechToText",
  props: {
    endpoint: String
  },
  data() {
    return {
      acceptExtensions: utils.speechToTextExtensions,
      sound: "",
      soundFile: null,
      prediction: "",
      confidence: "",
      force_disabled: false,
      prediction_label: utils.speech_to_text_label,
      show_form: false,
      uploadLbl: "",
      audioContext: null, //new AudioContext(),
      mediaRecorder: null,
      audioSource: null,
      isRecording: false,
      isPaused: false,
      audioChunks: [],
      utils: utils
    };
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }

      return !this.sound;
    },
    showDropZone: function() {
      return !this.sound;
    }
  },
  methods: {
    showSelectedSound: function(e) {
      const soundFile = e.target.files[0];
      this.uploadLbl = soundFile.name;

      this.sound = URL.createObjectURL(soundFile);

      this.predict();
    },
    dragover: function(e) {
      e.preventDefault();

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.add("drop-zone--over");
    },
    enddrag: function() {
      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
    },
    drop: function(e) {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        let inputEl = utils.el("sound-input");
        inputEl.files = e.dataTransfer.files;
        const soundFile = e.dataTransfer.files[0];
        this.sound = URL.createObjectURL(soundFile);
      }

      let dropzoneEl = document.querySelector(".drop-zone");
      dropzoneEl.classList.remove("drop-zone--over");
      this.predict();
    },
    selectSound: function() {
      utils.el("sound-input").click();
    },
    recordSound: function() {
      this.audioContext = new AudioContext();
      navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        this.audioSource = this.audioContext.createMediaStreamSource(stream);
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.addEventListener("dataavailable", event => {
          if (event.data.size > 0) {
            this.audioChunks.push(event.data);
          }
        });

        this.mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(this.audioChunks, {
            type: "audio/mp3"
          });
          const audioUrl = URL.createObjectURL(audioBlob);
          this.sound = audioUrl;
          // Create a File object from the Blob
          const audioFile = new File(
            [audioBlob],
            `recording-${new Date().toISOString()}.mp3`,
            {
              type: "audio/mp3",
              lastModified: new Date()
            }
          );

          // Create a DataTransfer object and add the file
          const dataTransfer = new DataTransfer();
          dataTransfer.items.add(audioFile);
          let inputEl = utils.el("sound-input");
          inputEl.files = dataTransfer.files;
          this.audioChunks = [];
          this.predict();
        });
        // Handle the stream
        this.startRecording();
      });
    },
    startRecording() {
      this.mediaRecorder.start();
      this.isRecording = true;
      this.isPaused = false;
    },
    pauseRecording() {
      this.mediaRecorder.pause();
      this.isRecording = false;
      this.isPaused = true;
    },
    resumeRecording() {
      this.mediaRecorder.resume();
      this.isRecording = true;
      this.isPaused = false;
    },
    stopRecording() {
      if (this.mediaRecorder && (this.isRecording || this.isPaused)) {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
      this.isPaused = false;
    },
    clickRecordBtn: function() {
      if (!this.isRecording || this.isPaused) {
        this.recordSound();
        return;
      }

      if (this.isRecording) {
        this.pauseRecording();
        return;
      }
    },
    getRecordingIcon: function() {
      if (!this.isRecording || this.isPaused) {
        return this.utils.getIcon("start-recording.svg");
      }

      if (this.isRecording) {
        return this.utils.getIcon("pause-recording.svg");
      }
    },
    predict: function() {
      this.prediction = "";
      this.confidence = "";
      this.force_disabled = true;
      this.prediction_label = utils.transcribe_in_progress;

      let inputEl = utils.el("sound-input");
      var soundFile = inputEl.files[0];
      var formData = new FormData();

      formData.append("file", soundFile);

      this.$http
        .post(this.endpoint, formData)
        .then(res => {
          this.prediction_label = utils.transcribe_another;

          let pred = JSON.parse(res.body.prediction.replace(/'/g, '"'));
          this.prediction = pred["text"].replace(/"/g, "'");

          this.force_disabled = false;
          this.show_form = true;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.transcribe_another;
        });
    }
  }
};
</script>

<style scoped lang="scss">
.drop-zone__thumb {
  width: 40%;
}

form {
  color: #666;
  font-size: 18px;
  margin-top: 20px;

  label {
    font-weight: 600;
  }
}

.audio-buttons {
  width: 40px;
  background-color: white;
  margin: 0 20px;
}

.audio-buttons :active {
  width: 38px;
}

.audio-image {
  width: 40px;
}

#next-btn {
  width: 200px;
  max-width: 200px;
  margin: 15px 0 0 0;
}

.disabled_input {
  opacity: 55%;
}
</style>
