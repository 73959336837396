<template>
  <div>
    <div class="search-container">
      <svg
        class="search-icon"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>

      <input
        type="text"
        id="search-input"
        class="search-input"
        required
        v-model="searchInput"
        :placeholder="utils.search_input_placeholder"
        @keyup.enter="pushSearch"
      />

      <button
        class="clear-button"
        aria-label="Clear search"
        @click="clearSearch"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="clear-button-svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
    <div>
      <button
        class="second-action-button action-btn"
        :disabled="btn_disabled"
        @click="pushSearch"
      >
        {{ prediction_label }}
      </button>
    </div>
    <form class="w-100 mx-auto" v-show="show_form">
      <div v-if="searchResults.length > 0">
        <Pagination
          :totalItems="corpus_length"
          :itemsPerPage="pageSize"
          :currentPage="pageOffset + 1"
          :maxVisiblePages="7"
          @page-changed="handlePageChange"
        />
        <ul>
          <li
            v-for="result in searchResults"
            :key="result.id"
            class="single-search-result"
          >
            <p v-if="result.metadata.filename">
              <strong>Filename: </strong
              ><a class="file-url" @click="showFile(result)">{{
                result.metadata.title + "." + result.metadata.extension
              }}</a>
            </p>
            <p><strong>Score: </strong>{{ result.confidence }}</p>
            <p><strong>Labels:</strong> {{ result.metadata.labels }}</p>
            <p><strong>Page: </strong>{{ result.index }}</p>
            <p><strong>Created: </strong>{{ result.metadata.created }}</p>
            <p><strong>Updated: </strong>{{ result.metadata.modified }}</p>
            <div v-html="displayResult(result.text)"></div>
            <hr />
          </li>
        </ul>
        <Pagination
          :totalItems="corpus_length"
          :itemsPerPage="pageSize"
          :currentPage="pageOffset + 1"
          :maxVisiblePages="7"
          @page-changed="handlePageChange"
        />
      </div>
      <div v-else>Nothing found</div>
    </form>
  </div>
</template>

<script>
import * as utils from "../../utils/utils.js";
import Pagination from "../Pagination.vue";

export default {
  name: "Search",
  props: {
    endpoint: String
  },
  components: {
    Pagination
  },
  data() {
    return {
      prediction: "",
      confidence: "",
      force_disabled: false,
      prediction_label: utils.search_input_placeholder,
      show_form: false,
      searchInput: "",
      searchResults: [],
      pageSize: 10,
      pageOffset: 0,
      corpus_length: 0,
      utils: utils
    };
  },
  computed: {
    btn_disabled: function() {
      if (this.force_disabled) {
        return true;
      }
      return this.searchInput.length == 0;
    }
  },
  watch: {
    "$route.query.pageOffset": {
      // Watch the 'id' parameter
      handler(newPageOffset) {
        //console.log("Route ID changed to:", newPageOffset);
        this.pageOffset = newPageOffset ? parseInt(newPageOffset) : 0;
        //this.itemId = newId;
        //this.fetchItemDetails(newId); // Call a function to fetch data
      },
      immediate: true // Run the handler immediately on component creation
    }
  },
  methods: {
    displayResult: function(text) {
      return utils.highlightWords(text, this.searchInput);
    },
    clearSearch: function() {
      this.searchInput = "";
      this.show_form = false;
      this.pageOffset = 0;
      this.pushSearch();
    },
    handlePageChange: function(page) {
      this.pageOffset = page - 1;
      this.pushSearch();
    },
    pushSearch: function() {
      let query = {};

      query["pageSize"] = this.pageSize;
      query["pageOffset"] = this.pageOffset;
      query["search"] = this.searchInput;

      this.$router.push({ path: this.$route.path, query: query });
      this.predict();
    },
    predict: function() {
      if (this.searchInput == "") {
        return;
      }

      this.prediction = "";
      this.force_disabled = true;
      this.prediction_label = utils.search_in_progress;

      this.searchInput = this.searchInput.trim();

      var data = {
        search: this.searchInput,
        page_size: this.pageSize,
        page_offset: this.pageOffset,
        options: {
          k: 2
        }
      };

      var searchData = {
        input_text: JSON.stringify(data)
      };

      this.$http
        .post(this.endpoint, searchData)
        .then(res => {
          this.prediction_label = utils.search_next;

          this.searchResults = res.data.inference_items.map(i => {
            let sr = JSON.parse(i.prediction);
            sr["confidence"] = i.confidence;
            return sr;
          });

          if (this.searchResults.length > 0) {
            this.corpus_length = this.searchResults[0].metadata.corpus_length;
          }

          this.force_disabled = false;
          this.show_form = true;
        })
        .catch(() => {
          this.force_disabled = false;
          this.prediction_label = utils.search_input_placeholder;
        });
    },
    showFile: function(result) {
      let metadata = result.metadata;

      let targetPage = result.index ? result.index : 0;

      let new_url = `/files/${metadata.item_id}/dataset/${metadata.dataset_id}/dataset_version/${metadata.dataset_version_id}?index=${targetPage}`;
      //let new_url = `/datasets/
      this.$router.push({ path: new_url });
    }
  },
  mounted() {
    let url_q = this.$route.query;

    this.pageOffset = url_q.pageOffset ? parseInt(url_q.pageOffset) : 0;
    this.pageSize = url_q.pageSize ? parseInt(url_q.pageSize) : this.pageSize;
    this.searchInput = url_q.search ? url_q.search : "";

    //console.log(this.pageSize)

    if (this.searchInput) {
      this.predict();
    }
  }
};
</script>

<style scoped lang="scss">
form {
  color: #666;
  font-size: 18px;

  label {
    font-weight: 600;
  }
}

.search-container {
  position: relative;
  max-width: 500px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif;
  margin: 0;
  padding: 0 20px;
  margin: 20px auto;
}

.search-input {
  width: 100%;
  padding: 12px 40px;
  font-size: 16px;
  border: 2px solid #e2e8f0;
  border-radius: 24px;
  outline: none;
  transition: all 0.3s ease;
}

.search-input:focus {
  border-color: #098fcf;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
}

/* Base link style */
.file-url {
  color: #0066cc; /* Default link color */
  text-decoration: none; /* Remove underline by default */
  transition: all 0.3s; /* Smooth transition between states */
}

/* When user hovers over the link */
.file-url:hover {
  color: #ff6600; /* Orange color on hover */
  text-decoration: underline; /* Add underline on hover */
}

/* When user is actively clicking the link */
.file-url:active {
  color: #cc0000; /* Red color when clicked/active */
}

/* For links that have been visited */
.file-url:visited {
  color: #660099; /* Purple color for visited links */
}

/* For links that receive keyboard focus */
.file-url:focus {
  color: #009900; /* Green color for focus */
  outline: 2px solid #009900; /* Green outline for accessibility */
  outline-offset: 2px; /* Small offset for the outline */
}

.search-input::placeholder {
  color: #94a3b8;
}

.search-icon {
  position: absolute;
  left: 50px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  pointer-events: none;
  color: #64748b;
}

.clear-button {
  position: absolute;
  right: 14px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  color: #64748b;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.clear-button:hover {
  color: #475569;
}

.search-input:valid ~ .clear-button {
  opacity: 1;
}

.clear-button-svg {
  vertical-align: baseline;
}

.single-search-result {
  text-align: left;
}
</style>
