<template>
  <div class="pagination-container">
    <!-- Navigation buttons -->
    <div class="pagination-controls">
      <a
        @click="goToPage(1)"
        :disabled="currentPage === 1"
        class="pagination-button"
      >
        &laquo; First
      </a>

      <a
        @click="goToPage(currentPage - 1)"
        :disabled="currentPage === 1"
        class="pagination-button"
      >
        &lsaquo; Prev
      </a>

      <!-- Page numbers -->
      <div class="page-numbers">
        <div v-for="item in displayedPages" :key="item.key">
          <div v-if="currentPage === item.page" class="page-number active">
            {{ item.page }}
          </div>
          <div v-else>
            <a
              v-if="item.page !== '...'"
              @click="goToPage(item.page)"
              :class="['page-number', { active: currentPage === item.page }]"
            >
              {{ item.page }}
            </a>
            <span v-else class="ellipsis">{{ item.page }}</span>
          </div>
        </div>
      </div>

      <a
        @click="goToPage(currentPage + 1)"
        :disabled="currentPage === totalPages"
        class="pagination-button"
      >
        Next &rsaquo;
      </a>

      <a
        @click="goToPage(totalPages)"
        :disabled="currentPage === totalPages"
        class="pagination-button"
      >
        Last &raquo;
      </a>
    </div>

    <!-- Jump to page -->
    <!-- <div class="jump-to-page">
      <span>Go to page:</span>
      <input 
        type="number" 
        v-model.number="jumpToPage" 
        min="1" 
        :max="totalPages" 
        @keyup.enter="handleJump"
      />
      <button @click="handleJump" class="jump-button">Go</button>
      
      <span class="page-info">
        Page {{ currentPage }} of {{ totalPages }}
      </span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    totalItems: {
      type: Number,
      required: true
    },
    itemsPerPage: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 0
    },
    maxVisiblePages: {
      type: Number,
      default: 7
    }
  },
  data() {
    return {
      jumpToPage: 1,
      updatedCurrentPage: 0
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
    displayedPages() {
      // Handle case with few pages
      if (this.totalPages <= this.maxVisiblePages) {
        return Array.from({ length: this.totalPages }, (_, i) => i + 1);
      }

      // Always show first page, last page, and pages around current
      const sidePages = Math.floor((this.maxVisiblePages - 3) / 2); // -3 for first, last, and current page

      let startPage = Math.max(2, this.currentPage - sidePages);
      let endPage = Math.min(this.totalPages - 1, this.currentPage + sidePages);

      // Adjust when current page is near start or end
      if (this.currentPage <= sidePages + 1) {
        endPage = this.maxVisiblePages - 1;
      } else if (this.currentPage >= this.totalPages - sidePages) {
        startPage = this.totalPages - this.maxVisiblePages + 2;
      }

      // Build the array of pages to display
      const pages = [{ page: 1, key: 1 }];

      if (startPage > 2) {
        pages.push({ page: "...", key: "1..." });
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push({ page: i, key: i });
      }

      if (endPage < this.totalPages - 1) {
        pages.push({ page: "...", key: "2..." });
      }

      if (this.totalPages > 1) {
        pages.push({ page: this.totalPages, key: this.totalPages });
      }

      return pages;
    }
  },
  mounted() {
    this.updatedCurrentPage = this.currentPage;
  },
  methods: {
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.updatedCurrentPage = page;
        this.jumpToPage = page;
        this.$emit("page-changed", page);
      }
    },
    handleJump() {
      if (this.jumpToPage >= 1 && this.jumpToPage <= this.totalPages) {
        this.goToPage(this.jumpToPage);
      } else {
        // Reset to valid value if out of range
        this.jumpToPage = this.updatedCurrentPage;
      }
    }
  }
};
</script>

<style scoped>
.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  font-family: Arial, sans-serif;
}

.pagination-controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.pagination-button {
  padding: 6px 12px;
  margin: 0 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.page-numbers {
  display: flex;
  margin: 0 10px;
  align-items: center;
}

.page-number {
  width: 34px;

  margin: 2px 6px;
  padding: 8px 2px;
  cursor: pointer;
}

.page-number.active {
  background-color: #098fcf;
  color: white;
  border-color: #098fcf;
}

.ellipsis {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
}

.jump-to-page {
  display: flex;
  align-items: center;
  gap: 8px;
}

.jump-to-page input {
  width: 60px;
  padding: 4px 8px;
  border-radius: 4px;
  text-align: center;
}

.jump-button {
  padding: 4px 8px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.jump-button:hover {
  background-color: #0069d9;
}

.page-info {
  margin-left: 15px;
  color: #6c757d;
}

/* Responsive design */
@media (max-width: 768px) {
  .pagination-controls {
    flex-wrap: wrap;
    justify-content: center;
  }

  .jump-to-page {
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
