<template>
  <div class="pdf-file-container">
    <div class="pdf-pagination">
      <!-- Page navigation controls -->
      <div class="pagination-controls">
        <button
          @click="previousPage"
          :disabled="!pdfHasPreviousPage()"
          class="pagination-button"
        >
          Previous
        </button>

        <div class="page-indicator">
          <input
            type="number"
            v-model.number="pdfCurrentPage"
            min="1"
            :max="pdfNumPages"
            @change="goToPage"
            class="page-input"
          />
          <span class="page-separator"> / </span>
          <span class="total-pages">{{ pdfNumPages }}</span>
        </div>

        <button
          @click="nextPage"
          :disabled="!pdfHasNextPage()"
          class="pagination-button"
        >
          Next
        </button>
      </div>
    </div>
    <div class="pdf-content">
      <pdf
        ref="pdfRef"
        :src="getImgUrl()"
        :page="pdfCurrentPage"
        @num-pages="pdfNumpagesCb"
      ></pdf>
    </div>
    <div>{{ pdfCurrentPage }} / {{ pdfNumPages }}</div>
    <!-- <a
      class="pdfPrevious"
      v-if="pdfHasPreviousPage()"
      @click.prevent.stop="pdfShowPreviousPage()"
    >
      <i class="fas fa-chevron-left"></i>
    </a>
    <a
      class="pdfNext"
      v-if="pdfHasNextPage()"
      @click.prevent.stop="pdfShowNextPage()"
    >
      <i class="fas fa-chevron-right"></i>
    </a> -->
  </div>
</template>

<script>
import * as utils from "../utils/utils.js";
import pdf from "vue-pdf";

export default {
  name: "File",
  components: {
    pdf
  },
  data: function() {
    return {
      pageNumber: 0,
      pdfCurrentPage: 1,
      pdfNumPages: 1,
      dataset_id: null,
      dataset_version_id: null,
      item_id: null
    };
  },
  methods: {
    getImgUrl: function() {
      var url = `${utils.datasetsApi}/${this.dataset_id}/versions/${this.dataset_version_id}/items/${this.item_id}/download`;

      return url;
    },
    pdfHasNextPage: function() {
      return this.pdfCurrentPage < this.pdfNumPages;
    },
    pdfShowNextPage: function() {
      this.pdfCurrentPage += 1;
    },
    pdfHasPreviousPage: function() {
      return this.pdfCurrentPage > 1;
    },
    pdfShowPreviousPage: function() {
      this.pdfCurrentPage -= 1;
    },
    pdfNumpagesCb: function(numPages) {
      this.pdfNumPages = numPages;
    },
    goToPage: function() {
      if (this.pageNumber < 1) {
        this.pageNumber = 1;
      }
      if (this.pageNumber > this.pdfNumPages) {
        this.pageNumber = this.pdfNumPages;
      }
      this.pdfCurrentPage = this.pageNumber;
    },
    previousPage: function() {
      this.pdfShowPreviousPage();
    },
    nextPage: function() {
      this.pdfShowNextPage();
    }
  },
  mounted() {
    utils.showMenu(false, false, this);
    let params = this.$route.params;
    this.dataset_id = params.dataset_id;
    this.dataset_version_id = params.dataset_version_id;
    this.item_id = params.item_id;

    let query = this.$route.query;
    this.pdfCurrentPage = query.index ? parseInt(query.index) : 0;
  }
};
</script>
<style>
.pdf-file-container {
  background-color: gray;
}
.pdf-content {
  width: 60%;
  margin: auto 0;
  display: inline-block;
}

.pdf-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
}

.pagination-controls {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.pagination-button {
  padding: 6px 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 20px;
}

.pagination-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
